<template>
  <div class="">
    <router-link
      :to="{
        name: 'Team-Collection-Base-Home',
        params: { team_slug: team.slug, collection_slug: collection.slug, base_id: base_id },
      }"
      v-if="collection && team"
    >
      <div v-if="base" class="text-center p-2 bg-gray-400 text-white">
        {{ base.name }}
      </div>
    </router-link>
    <router-view :base_id="base_id" />
  </div>
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted } from "vue";

import Navigation from "@/components/Navigation.vue";
import router from "@/router";

export default {
  name: "Team-Collection-Base",
  components: {
    Navigation,
  },
  props: {
    base_id: String,
  },
  setup(props) {
    const team = computed(() => {
      return store.state.team.item;
    });
    const collection = computed(() => {
      return store.state.team.collection;
    });

    const base = computed(() => {
      return store.getters["team/getBase"](props.base_id);
    });

    onMounted(() => {});

    onUnmounted(() => {});

    return { base, team, collection };
  },
};
</script>
