<template>
  <FormGroup :label="label" :inputId="idCalc" :errors="errorsCalc" :successes="successes">
    <select
      class="form-control select-input"
      :class="{ 'is-invalid': errorsCalc, 'is-valid': successes, 'text-rocks': !modelValue }"
      :data-test="testId"
      :id="idCalc"
      :name="name"
      :value="modelValue"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <slot>
        <option v-if="noneLabel" value="">
          {{ noneLabel }}
        </option>
        <option :key="option.value" v-for="option in allOptions" :value="option.value">
          {{ option.label }}
        </option>
      </slot>
    </select>
  </FormGroup>
</template>

<script>
import FormGroup from "@/components/FormGroup";
import FormMixin from "@/components/FormMixin";
import { computed } from "@vue/reactivity";

export default {
  name: "FormSelect",
  components: {
    FormGroup,
  },
  mixins: [FormMixin],
  emits: ["update:modelValue"],
  props: {
    label: String,
    modelValue: [String, Number, Boolean, Array],
    options: {
      type: Array,
      validator: (vals) => {
        if (!Array.isArray(vals)) {
          return false;
        }

        return vals;
      },
    },
    noneLabel: String,
  },
  setup(props) {
    const allOptions = computed(() => {
      if (props?.options?.[0]?.label) {
        return props.options;
      }

      return props.options?.map((o) => {
        return {
          value: o,
          label: o,
        };
      });
    });

    return {
      allOptions,
    };
  },
};
</script>

<style lang="postcss" scoped>
.select-input {
  padding-right: 40px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: url("data:image/svg+xml;utf8,<svg width='13' height='11' viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6.5 11L0.00480934 0.5L12.9952 0.500001L6.5 11Z' fill='%23999999'/></svg>")
    no-repeat;
  background-position: right 7px top 50%;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  @apply bg-eggshell;
}
</style>
