<template>
  <div class="home pt-4 container">
    <div class="flex justify-between items-center mb-4">
      <div class="left flex items-center">
        <button @click="newFile" class="btn btn-sm btn-outline mr-2">Add Image</button>
      </div>
      <div class="right"></div>
    </div>
    <div class="flex items-end mb-4">
      <form-input
        v-model="searchTerm"
        label="Search Term"
        placeholder="Name, attributes"
        class="mr-4"
      />
      <form-select
        v-model="flaggedSetting"
        :options="flaggedOptions"
        label="Show Only Flagged"
        class="mr-4"
      />
      <button type="button" @click="refresh" class="btn btn-sm">Search</button>
    </div>
    <div v-if="imageFiles">
      <div class="">
        <image-file-item
          :file="file"
          v-for="(file, f) in imageFiles.image_files"
          :key="f"
          @editFile="editFile"
          @deleteFile="deleteFile"
        />
      </div>
      <pagination
        :currentPage="imageFiles?.current_page"
        :totalPages="imageFiles?.total_pages"
        @page="refresh"
      />
    </div>
  </div>
  <modal :cancel="() => (modalFile = null)" v-if="modalFile">
    <div>
      <div class="text-lg font-bold mb-8">{{ modalFile.id ? "Update" : "Create" }} File</div>
      <div v-if="modalFile.id" class="mx-auto w-full" style="max-width: 300px">
        <img :src="imageUrl(modalFile.id)" />
      </div>
      <div class="my-2 text-sm text-gray-400 text-center" v-if="modalFile?.file_name_original">
        Original file name: {{ modalFile.file_name_original }}
      </div>
      <div class="my-2 text-sm text-gray-400 text-center" v-if="modalFile?.base_files?.length">
        Base files using this image:
        {{ modalFile.base_files.map((f) => `${f.name} (ID: ${f.id})`).join(", ") }}
      </div>
      <form-input v-model="modalFile.name" label="Name" class="mb-6" />
      <form-array-values v-model="modalFile.attributesn" label="Attributes" class="mb-6" />
      <div class="mb-6">
        <label class="block mb-1 text-sm">{{ modalFile.id ? "Update File" : "Upload File" }}</label>
        <input type="file" name="file" @change="setFile" />
      </div>
      <button @click="submit" class="btn">Save</button>
    </div>
  </modal>
  <modal v-if="modalDeleteFile" :cancel="() => (modalDeleteFile = null)">
    <div class="flex flex-col items-center justify-center text-center">
      <div class="text-red font-bold mb-4">Are you sure you want to delete this file?</div>
      <div v-if="modalDeleteFile.id" class="mx-auto w-full" style="max-width: 300px">
        <img :src="imageUrl(modalDeleteFile.id)" />
      </div>
      <div class="my-2 text-sm text-gray-400 text-center" v-if="modalFile?.file_name_original">
        Original file name: {{ modalFile.file_name_original }}
      </div>
      <div
        class="my-2 text-sm text-gray-400 text-center"
        v-if="modalDeleteFile?.base_files?.length"
      >
        Base files using this image:
        {{ modalDeleteFile.base_files.map((f) => `${f.name} (ID: ${f.id})`).join(", ") }}
      </div>
      <div class="mb-4">{{ modalDeleteFile.name }}</div>
      <div class="mb-6 italic">
        This will also remove all base files using this file. This cannot be undone.
      </div>
      <button @click="confirmDeleteFile" class="btn btn-danger">Confirm Delete</button>
    </div>
  </modal>
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted } from "vue";
import FolderItem from "@/components/FolderItem.vue";
import ImageFileItem from "@/components/ImageFileItem.vue";
import FileItem from "@/components/FileItem.vue";
import FormArrayValues from "@/components/FormArrayValues.vue";
import FormInput from "@/components/FormInput.vue";
import FormSelect from "@/components/FormSelect.vue";
import router from "@/router";
import Folder from "@/classes/Folder";
import File from "@/classes/File";
import Modal from "@/components/Modal.vue";
import FileForm from "@/components/FileForm.vue";
import FolderForm from "@/components/FolderForm.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "Team-Collection-Images",
  components: {
    FolderItem,
    FileItem,
    FormArrayValues,
    Modal,
    FileForm,
    FolderForm,
    FormInput,
    ImageFileItem,
    Pagination,
    FormSelect,
  },
  props: {},
  setup(props) {
    const collection = computed(() => {
      return store.state.team.collection;
    });

    const team = computed(() => {
      return store.state.team.item;
    });

    const imageFiles = ref(null);
    const modalFile = ref(null);
    const modalDeleteFile = ref(null);
    const currentPage = ref(1);
    const searchTerm = ref(null);
    const newFile = () => {
      modalFile.value = {
        id: null,
        name: null,
        attributes: [],
        base_files: [],
      };
    };

    const modalFileCloseRefresh = () => {
      modalFile.value = null;
      refresh();
    };

    const flaggedSetting = ref("");
    const refresh = (page = 1) => {
      if (imageFiles.value?.image_files) {
        imageFiles.value.image_files = null;
      }
      store
        .dispatch("team/getImageFiles", {
          page,
          search: searchTerm.value,
          flagged_only: flaggedSetting.value,
        })
        .then((data) => {
          imageFiles.value = data;
          currentPage.value = data.current_page;
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const editFile = (file) => {
      modalFile.value = { ...file };
    };

    const deleteFile = (file) => {
      modalDeleteFile.value = { ...file };
    };

    const uploadFile = ref(null);
    const setFile = (event) => {
      uploadFile.value = event.target.files[0];
    };

    const confirmUploadFile = async () => {
      try {
        let fileUpload = await store.dispatch("team/uploadFile", {
          id: modalFile.value.id,
          file: uploadFile.value,
          attributesn: modalFile.value.attributesn,
          name: modalFile.value.name,
        });
      } catch (e) {
        store.dispatch("toasts/error", ["Could not upload file", e]);
      }
      uploadFile.value = null;
      modalFile.value = null;

      refresh(currentPage.value);
    };

    const confirmUpdateFile = async () => {
      try {
        let fileUpload = await store.dispatch("team/updateFile", {
          id: modalFile.value.id,
          attributesn: modalFile.value.attributesn,
          name: modalFile.value.name,
        });
      } catch (e) {
        store.dispatch("toasts/error", ["Could not update file", e]);
        return;
      }

      modalFile.value = null;

      refresh(currentPage.value);
    };

    const submit = async () => {
      if (uploadFile.value) {
        await confirmUploadFile();
      } else {
        await confirmUpdateFile();
      }
    };

    const confirmDeleteFile = async () => {
      try {
        let fileDeletion = await store.dispatch("team/deleteFile", {
          id: modalDeleteFile.value.id,
        });
      } catch (e) {
        console.log("error deleting: ", e);
        store.dispatch("toasts/error", ["Could not delete file", e]);
        return;
      }

      modalDeleteFile.value = null;

      refresh(currentPage.value);
    };

    const imageUrl = (id) => {
      return store.getters["team/collectionUrl"](id);
    };

    const flaggedOptions = [
      {
        value: "",
        label: "No",
      },
      {
        value: "yes",
        label: "Yes",
      },
    ];

    onMounted(() => {
      refresh();
    });

    onUnmounted(() => {});

    return {
      team,
      collection,
      imageFiles,
      modalFile,
      newFile,
      editFile,
      modalFileCloseRefresh,
      modalDeleteFile,
      confirmDeleteFile,
      deleteFile,
      submit,
      setFile,
      imageUrl,
      refresh,
      searchTerm,
      flaggedOptions,
      flaggedSetting,
    };
  },
};
</script>
