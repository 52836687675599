<template>
  <div class="">
    <div class="text-lg flex items-center cursor-pointer py-2 justify-between" @click="toggleOpen">
      <div class="left flex items-center">
        <div class="mr-2">
          <img
            :src="require('@/assets/right-arrow.svg')"
            style="width: 15px"
            class="transform"
            :class="{ 'rotate-90': isOpen }"
          />
        </div>
        <div class="flex items-center">
          <div class="mr-2">
            <div>
              [{{ folder.order ?? 0 }}] {{ folder.name }}
              <span class="text-xs text-gray-400">(ID: {{ folder.id }})</span>
            </div>
            <div class="text-xs text-gray-400">
              <span v-if="attributesn?.length">
                Attributes: {{ attributesn.map((a) => `${a.key}: ${a.value}`).join(", ") }}
                <br />
              </span>
              <span v-if="restrictions?.length">
                Restrictions: {{ restrictions.map((a) => `${a.key}: ${a.value}`).join(", ") }}
                <br />
              </span>
              <span v-if="modifiers?.length">
                Modifiers: {{ modifiers.map((a) => `${a.key}: ${a.value}`).join(", ") }}
              </span>
            </div>
          </div>
          <Tag :name="folder.choice" :color="folderChoiceColor" class="mr-1" />
          <Tag :name="`L: ${folder.layer}`" color="gray" class="mr-1" v-if="folder.layer" />
          <Tag
            :name="`P: ${folder.probability}`"
            color="red"
            class="mr-1"
            v-if="folder.probability !== null"
          />
          <Tag
            :name="`W: ${calculatedWight}`"
            color="pink"
            class="mr-1"
            v-if="folderChoice !== 'and'"
          />
          <Tag :name="`T: ${folder.type}`" color="yellow" class="mr-1" v-if="folder.type" />
        </div>
      </div>
      <div class="flex-grow border-t mx-4"></div>
      <div class="right">
        <button class="btn btn-sm ml-2" @click.stop="editFolder(folder)" type="button">
          <img :src="require('@/assets/edit-white.svg')" style="height: 20px" />
        </button>
        <button class="btn btn-sm btn-danger ml-2" @click.stop="deleteFolder(folder)" type="button">
          <img :src="require('@/assets/trash-white.svg')" style="height: 20px" />
        </button>
      </div>
    </div>
    <div class="ml-2 pl-4 border-l" v-if="isOpen">
      <folder-item
        :folder="fold"
        v-for="(fold, f) in folder.folders"
        :key="f"
        @editFile="editFile"
        @deleteFile="deleteFile"
        @addFile="addFile"
        @editFolder="editFolder"
        @deleteFolder="deleteFolder"
        @flagFile="flagFile"
        :folderChoice="folder.choice"
        :baseId="baseId"
      />

      <file-item
        :file="file"
        v-for="(file, f) in folder.files"
        :key="f"
        @edit="editFile"
        @add="addFile"
        @deleteFile="deleteFile"
        @flagFile="flagFile"
        :folderChoice="folder.choice"
        :baseId="baseId"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import FileItem from "@/components/FileItem.vue";
import Tag from "@/components/Tag.vue";

export default {
  name: "FolderItem",
  components: {
    FileItem,
    Tag,
  },
  props: {
    folder: Object,
    folderChoice: String,
    baseId: [String, Number],
  },
  emits: ["editFile", "deleteFile", "addFile", "deleteFolder", "editFolder", "flagFile"],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const editFile = (file) => {
      emit("editFile", file);
    };

    const deleteFile = (file) => {
      emit("deleteFile", file);
    };

    const addFile = (file) => {
      emit("addFile", file);
    };

    const editFolder = (fold) => {
      emit("editFolder", fold);
    };

    const flagFile = (file) => {
      emit("flagFile", file);
    };

    const deleteFolder = (fold) => {
      emit("deleteFolder", fold);
    };

    const folderChoiceColor = computed(() => {
      return props.folder.choice === "between"
        ? "purple"
        : props.folder.choice === "and"
        ? "orange"
        : "red";
    });

    const toggleOpen = () => {
      isOpen.value = !isOpen.value;
    };

    const calculatedWight = computed(() => {
      if (props.folder.weight === 0) {
        return 0;
      }

      if (!props.folder.weight) {
        return 1;
      }

      return props.folder.weight;
    });

    const attributesn = computed(() => {
      if (!props.folder.attributesn || Array.isArray(props.folder.attributesn)) {
        return null;
      }

      let keys = Object.keys(props.folder.attributesn);
      return keys.map((k) => ({
        key: k,
        value: props.folder.attributesn[k],
      }));
    });

    const restrictions = computed(() => {
      if (!props.folder.restrictions || Array.isArray(props.folder.restrictions)) {
        return null;
      }

      let keys = Object.keys(props.folder.restrictions);
      return keys.map((k) => ({
        key: k,
        value: props.folder.restrictions[k],
      }));
    });

    const modifiers = computed(() => {
      if (!props.folder.modifiers || Array.isArray(props.folder.modifiers)) {
        return null;
      }

      let keys = Object.keys(props.folder.modifiers);
      return keys.map((k) => ({
        key: k,
        value: props.folder.modifiers[k],
      }));
    });

    return {
      isOpen,
      toggleOpen,
      editFile,
      deleteFile,
      addFile,
      editFolder,
      deleteFolder,
      folderChoiceColor,
      calculatedWight,
      attributesn,
      restrictions,
      modifiers,
      flagFile,
    };
  },
};
</script>

<style lang="postcss"></style>
