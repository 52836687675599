<template>
  <div class="home pt-4">
    <div class="container">
      <div class="flex items-center justify-between mb-6">
        <div class="left">
          <router-link
            :to="{
              name: 'Team-Collection-Base-Sets',
              params: {
                team_slug: team.slug,
                collection_slug: collection.slug,
                base_id: base_id,
              },
            }"
            class="btn btn-sm btn-outline mr-2"
          >
            Go To Sets
          </router-link>
          <button class="btn btn-sm" @click="() => (addModal = true)">Add Output</button>
        </div>
        <div class="right">
          <button @click="downloadOutputs" class="btn btn-sm btn-outline mr-2">
            Download Summary
          </button>
          <!-- <button @click="deleteAllBaseSets" class="btn btn-danger btn-sm">Delete All</button> -->
        </div>
      </div>
      <div class="mb-2 flex items-center" v-if="outputMetadata">
        <form-select
          v-model="tempMetadata"
          :options="metadataMenu"
          @change="toggleMetadata"
          noneLabel="Filter by Attribute"
          class="mr-4"
        />
        <div class="flex flex-wrap flex-grow">
          <template v-for="(selected, s) in selectedMetadatas" :key="s">
            <button
              v-for="(val, v) in selected.values"
              :key="v"
              @click="toggleMetadata(`${selected.key}|-|${val}`)"
              class="text-xs bg-orange text-white px-2 py-1 rounded-full mr-1 mb-1"
            >
              {{ selected.key }}: {{ val }} (X)
            </button>
          </template>
        </div>
        <form-select
          v-model="showFromSets"
          :options="[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ]"
          label="Show From Sets"
          class="mr-4"
          @change="refreshData"
        />
      </div>
      <div class="mb-4 flex items-center text-sm text-gray-500" v-if="outputMetadata">
        Current selections:
        {{ Math.round((outputMetadata.count / outputMetadata.total) * 1000) / 10 }}% ({{
          outputMetadata.count
        }}
        / {{ outputMetadata.total }}); *Generated from a set
      </div>
      <div v-if="outputs?.outputs">
        <output-item
          v-for="(output, s) in outputs.outputs"
          :key="s"
          :output="output"
          @editOutput="editOutput"
          @saveOutput="toggleBaseOutputSave"
          @deleteOutput="deleteBaseOutput"
        />
        <pagination
          :currentPage="outputs?.current_page"
          :totalPages="outputs?.total_pages"
          @page="refreshData"
        />
      </div>
    </div>
  </div>
  <modal v-if="addModal" :cancel="closeModal">
    <form @submit.prevent="upload" class="flex flex-col items-center justify-center pt-2">
      <div class="border mb-4"><input type="file" @change="setFile" /></div>
      <div><button type="submit" class="btn">Submit</button></div>
    </form>
  </modal>
  <modal v-if="editingOutput" :cancel="cancelOutput">
    <div class="flex items-center justify-between mb-4">
      <button
        @click="goToPreviousOutput"
        type="button"
        class="btn btn-sm btn-outline"
        :disabled="previousOutputIndex < 0"
      >
        &lt; Previous
      </button>
      <button
        @click="goToNextOutput"
        type="button"
        class="btn btn-sm btn-outline"
        :disabled="nextOutputIndex < 0"
      >
        Next &gt;
      </button>
    </div>
    <div class="mb-4 font-bold">{{ editingOutput.display_name }}</div>
    <div class="mb-4 mx-auto" style="max-width: 500px">
      <img :src="outputImageUrl(editingOutput.id)" />
    </div>
    <form @submit.prevent="updateOutput" class="">
      <div class="mb-4">
        <form-input v-model="editingOutput.name" label="Output Name" />
      </div>
      <div class="mb-4">
        <div class="mb-2">Metadata Key/Values</div>
        <FormArrayValues v-model="editingOutput.metadata" v-if="!resetOutputAttributes" />
      </div>
      <hr class="mb-4" />
      <div class="mb-2 text-center">
        <button type="submit" class="btn">Update</button>
      </div>
    </form>
  </modal>
  <loader v-if="loading" />
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted, reactive, unref } from "vue";
import Navigation from "@/components/Navigation.vue";
import router from "@/router";
import Modal from "@/components/Modal.vue";
import FormInput from "@/components/FormInput.vue";
import FormSelect from "@/components/FormSelect.vue";
import Pagination from "@/components/Pagination.vue";
import OutputItem from "@/components/OutputItem.vue";
import Loader from "@/components/Loader.vue";
import FormArrayValues from "@/components/FormArrayValues.vue";

export default {
  name: "Team-Collection-Base-Files",
  components: {
    Navigation,
    Modal,
    FormInput,
    FormSelect,
    OutputItem,
    Pagination,
    FormArrayValues,
    Loader,
  },
  props: {
    base_id: String,
  },
  setup(props) {
    const base = ref(null);
    const loading = ref(true);
    const outputs = ref(null);
    const addModal = ref(false);
    const collection = computed(() => {
      return store.state.team.collection;
    });

    const team = computed(() => {
      return store.state.team.item;
    });

    const uploadFile = ref(null);
    const setFile = (event) => {
      uploadFile.value = event.target.files[0];
    };

    const loadingUpload = ref(false);

    const outputImageUrl = (id) => {
      return store.getters["team/outputUrl"](props.base_id, id);
    };

    const showFromSets = ref("yes");

    const editForm = reactive({
      name: null,
      metadata: [],
    });
    const editingOutput = ref(null);
    const editOutput = (data) => {
      editingOutput.value = data;
      // editForm.name = data.name;
      // editForm.metadata = data.metadata ?? [];
    };
    const cancelOutput = () => {
      editingOutput.value = null;
    };
    const updateOutput = () => {
      loading.value = true;
      store
        .dispatch("team/updateBaseOutput", {
          base_id: props.base_id,
          id: editingOutput.value.id,
          name: editingOutput.value.name,
          metadata: editingOutput.value.metadata,
        })
        .then(() => {
          refreshData();
          cancelOutput();
        })
        .catch((e) => {
          store.dispatch("toasts/error", ["Could not update", e]);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    // const addTrait = () => {
    //   editingOutput.metadata = [...editingOutput.metadata, { value: null, trait_type: null }];
    // };
    // const removeTrait = (k) => {
    //   let copyOfArray = editForm.metadata.slice(0);
    //   copyOfArray.splice(k, 1);
    //   editForm.metadata = copyOfArray;
    // };

    const closeModal = () => {
      if (loadingUpload.value) {
        return;
      }
      uploadFile.value = null;
      addModal.value = false;
    };

    const upload = () => {
      loading.value = true;
      store
        .dispatch("team/uploadBaseOutput", {
          base_id: props.base_id,
          file: uploadFile.value,
        })
        .then((data) => {
          // console.log(data);
          refreshData();
          addModal.value = false;
          uploadFile.value = null;
        })
        .catch((e) => {
          store.dispatch("toasts/error", ["Could not upload", e]);
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const outputMetadata = ref(null);
    const refreshData = async (page = null) => {
      loading.value = true;
      let data = await store.dispatch("team/getBaseOutputs", {
        base_id: props.base_id,
        page,
        metadata: metadataForSearch.value,
        include_sets: showFromSets.value,
      });
      outputs.value = data;
      loading.value = false;
      outputMetadata.value = await store.dispatch("team/getBaseOutputsMetadata", {
        base_id: props.base_id,
        metadata: metadataForSearch.value,
      });

      // .then((data) => {
      //   loading.value = false;
      //   outputs.value = data;
      // })
      // .catch((e) => {
      //   store.dispatch("toasts/error", "Could not load outputs");
      //   router.push({ name: "Home" });
      // })
      // .finally(() => {
      //   loading.value = false;
      // });
    };

    const deleteBaseOutput = async (output) => {
      loading.value = true;
      try {
        await store.dispatch("team/deleteBaseOutput", { base_id: props.base_id, id: output.id });
      } catch (e) {
        store.dispatch("toasts/error", ["Could not delete output", e]);
        return;
      }

      await refreshData();

      store.dispatch("toasts/success", ["Deleted output"]);
      loading.value = false;
    };

    const toggleBaseOutputSave = () => {};

    const downloadOutputs = async () => {
      loading.value = true;
      await store.dispatch("team/getBaseOutputsExport", { base_id: props.base_id });
      loading.value = false;
    };
    const selectedMetadatas = ref([]);
    const tempMetadata = ref(null);
    const toggleMetadata = (attrString = null) => {
      // attr = {key: "", value: ""}
      if (tempMetadata.value || attrString) {
        let attrArr = tempMetadata.value
          ? tempMetadata.value.split("|-|")
          : attrString.split("|-|");
        let attr = {
          key: attrArr[0],
          value: attrArr[1],
        };
        let copySelected = unref([...selectedMetadatas.value]);

        let foundAttr = copySelected.findIndex((s) => s.key == attr.key);

        if (foundAttr > -1) {
          let foundVal = copySelected[foundAttr].values.indexOf(attr.value);

          if (foundVal < 0) {
            copySelected[foundAttr].values = [...copySelected[foundAttr].values, attr.value];
          } else {
            copySelected[foundAttr].values = [
              ...copySelected[foundAttr].values.filter((a) => a != attr.value),
            ];
          }
        } else {
          copySelected = [
            ...copySelected,
            {
              key: attr.key,
              values: [attr.value],
            },
          ];
        }
        console.log({ copySelected });
        selectedMetadatas.value = [...copySelected];
        tempMetadata.value = null;
        refreshData();
      }
    };
    const metadataMenu = computed(() => {
      if (!outputMetadata.value?.metadata) {
        return null;
      }
      let keys = Object.keys(outputMetadata.value?.metadata);
      let menu = [];
      keys.forEach((k) => {
        outputMetadata.value.metadata[k].forEach((a) => {
          let stats = outputMetadata.value.properties[`${k}-${a}`];
          if (
            !selectedMetadatas.value?.find((s) => {
              return s.values.find((v) => v == a);
            })
          ) {
            menu = [
              ...menu,
              {
                value: `${k}|-|${a}`,
                label: `${k}: ${a} (${stats})`,
              },
            ];
          }
        });
      });
      return menu.sort((a, b) => (a.label > b.label ? 1 : -1));
    });
    const metadataForSearch = computed(() => {
      let newObj = {};

      selectedMetadatas.value?.forEach((a) => {
        if (a?.values?.length) {
          newObj[a.key] = a.values;
        }
      });

      return newObj;
    });

    const resetOutputAttributes = ref(false);
    const previousOutputIndex = computed(() => {
      const index = outputs.value?.outputs?.findIndex((s) => s.id === editingOutput.value?.id);
      return index > 0 ? index - 1 : -1;
    });
    const goToPreviousOutput = () => {
      if (previousOutputIndex.value > -1) {
        resetOutputAttributes.value = true;
        editingOutput.value = outputs.value?.outputs?.[previousOutputIndex.value];
        setTimeout(() => {
          resetOutputAttributes.value = false;
        }, 1);
      }
    };

    const nextOutputIndex = computed(() => {
      const index = outputs.value?.outputs?.findIndex((s) => s.id === editingOutput.value?.id);
      return index > -1 && index + 1 < outputs.value?.outputs?.length ? index + 1 : -1;
    });
    const goToNextOutput = () => {
      if (nextOutputIndex.value > -1) {
        resetOutputAttributes.value = true;
        editingOutput.value = outputs.value?.outputs?.[nextOutputIndex.value];
        setTimeout(() => {
          resetOutputAttributes.value = false;
        }, 1);
      }
    };

    onMounted(() => {
      refreshData();
    });

    onUnmounted(() => {});

    return {
      showFromSets,
      resetOutputAttributes,
      previousOutputIndex,
      goToPreviousOutput,
      nextOutputIndex,
      goToNextOutput,
      loading,
      team,
      collection,
      base,
      outputs,
      addModal,
      uploadFile,
      setFile,
      upload,
      outputImageUrl,
      closeModal,
      editingOutput,
      editOutput,
      cancelOutput,
      editForm,
      updateOutput,
      // addTrait,
      // removeTrait,
      deleteBaseOutput,
      toggleBaseOutputSave,
      refreshData,
      downloadOutputs,
      metadataForSearch,
      metadataMenu,
      toggleMetadata,
      selectedMetadatas,
      tempMetadata,
      outputMetadata,
    };
  },
};
</script>
