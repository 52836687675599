<template>
  <div class="home pt-4">
    <div class="flex flex-col max-w-xl mx-auto px-8">
      <div class="text-blue text-2xl py-4 text-center font-medium">Collections</div>
      <div
        class="shadow bg-white rounded overflow-hidden mb-8"
        v-for="(collection, c) in collections"
        :key="c"
      >
        <div class="p-4 text-center">
          <div class="mb-4 font-semibold text-blue">
            {{ collection.name }}
          </div>
          <div class="mb-0">
            <router-link
              :to="{
                name: 'Team-Collection-Home',
                params: { team_slug: team.slug, collection_slug: collection.slug },
              }"
              class="btn btn"
              >Go To Collection</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted } from "vue";

import Navigation from "@/components/Navigation.vue";

export default {
  name: "Team-Home",
  components: {
    Navigation,
  },
  setup() {
    const collections = computed(() => {
      return store.state.team.item?.collections;
    });

    const team = computed(() => {
      return store.state.team.item;
    });

    // onMounted(() => {
    //   store.dispatch("loading/setGlobal", true);
    //   store
    //     .dispatch("auth/getUser")
    //     .then((data) => {})
    //     .catch((e) => {
    //       console.log("couldn't get user", e);
    //     })
    //     .finally(() => {
    //       store.dispatch("loading/setGlobal", false);
    //     });
    // });

    onUnmounted(() => {});

    return { collections, team };
  },
};
</script>
