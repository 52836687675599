import BaseChild from "./BaseChild";

class Folder extends BaseChild {
  constructor(data = {}) {
    super(data);
    this.choice = data?.choice ?? "and";
    this.between = data?.between ?? [];
    this.files = data?.files ?? [];
    this.folders = data?.folders ?? [];
  }
}

export default Folder;
