<template>
  <div class="home pt-4">
    <div class="flex flex-col max-w-xl mx-auto px-8">
      <div class="text-blue text-2xl py-4 text-center font-medium">Teams</div>
      <div
        class="shadow bg-white rounded overflow-hidden mb-8"
        v-for="(team, t) in user.teams"
        :key="t"
      >
        <div class="p-4 text-center">
          <div class="mb-4 font-semibold text-blue">
            {{ team.name }}
          </div>
          <div class="mb-0">
            <router-link
              :to="{ name: 'Team-Home', params: { team_slug: team.slug } }"
              class="btn btn"
              >Go To Team</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted } from "vue";

import Navigation from "@/components/Navigation.vue";

export default {
  name: "Home",
  components: {
    Navigation,
  },
  setup() {
    const user = computed(() => {
      return store.state.auth.user;
    });

    onMounted(() => {
      store.dispatch("loading/setGlobal", true);
      store
        .dispatch("auth/getUser")
        .then((data) => {})
        .catch((e) => {
          console.log("couldn't get user", e);
        })
        .finally(() => {
          store.dispatch("loading/setGlobal", false);
        });
    });

    onUnmounted(() => {});

    return { user };
  },
};
</script>
