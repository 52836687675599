<template>
  <navigation />
  <div class="">
    <router-view v-if="!loading" />
    <div v-else class="p-12 text-center text-gray-400">Loading...</div>
    <div class="py-8 px-4 text-center text-sm text-gray-400">
      © 2021, Hashku. A
      <a class="underline" href="https://www.cartwheel.studio/" rel="noref noopener" target="_blank"
        >Cartwheel Startup Studio, Inc</a
      >
      Company. All rights reserved.
    </div>
  </div>
  <global-loader v-if="globalLoading" />
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted } from "vue";
// import { DateTime } from "luxon";
import Navigation from "@/components/Navigation.vue";
import GlobalLoader from "@/components/GlobalLoader.vue";

export default {
  name: "Dashboard",
  components: {
    Navigation,
    GlobalLoader,
  },
  setup() {
    const loading = ref(true);
    onMounted(() => {
      store
        .dispatch("auth/getUser")
        .then((data) => {
          loading.value = false;
        })
        .catch((e) => {
          console.log("dashboard couldn't get user", e);
        });
    });

    const phoneModal = computed(() => {
      return store.state.auth.phone_modal;
    });

    const emailModal = computed(() => {
      return store.state.auth.email_modal;
    });

    const addEmailModal = computed(() => {
      return store.state.auth.add_email_modal;
    });

    const subscriptionAlert = computed(() => {
      return store.state.auth.subscription_alert_modal;
    });

    const globalLoading = computed(() => {
      return store.state.loading.global;
    });

    return {
      loading,
      phoneModal,
      emailModal,
      subscriptionAlert,
      globalLoading,
      addEmailModal,
    };
  },
};
</script>
