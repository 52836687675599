<template>
  <div
    class="
      fixed
      top-0
      bottom-0
      right-0
      left-0
      bg-black
      opacity-20
      flex flex-col
      justify-center
      items-center
      z-50
    "
  >
    <div>
      <img :src="require('@/assets/tail-spin.svg')" style="width: 100px" />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "Loader",
  props: {
    number: [Number, String],
  },
  setup(props) {
    return {};
  },
};
</script>

<style lang="postcss"></style>
