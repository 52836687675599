<template>
  <div class="">
    <label class="block mb-1 text-sm">{{ label }}</label>
    <div v-for="(item, i) in arrayOfItems.set" :key="i" class="flex items-center mb-4">
      <form-input v-model="item.key" placeholder="key" class="flex-grow" />
      <form-input v-model="item.value" placeholder="value" class="flex-grow ml-4" />
      <button type="button" @click="removeItem(i)" class="btn btn-sm btn-outline ml-4">
        Remove
      </button>
    </div>
    <div>
      <button type="button" @click="addItem" class="btn btn-sm">Add More</button>
    </div>
  </div>
</template>

<script>
import { computed, ref, reactive, watch } from "vue";
import FormInput from "@/components/FormInput";
import FormGroup from "@/components/FormGroup";
import FormMixin from "@/components/FormMixin";

class Attribute {
  constructor(key, value) {
    this.key = key;
    this.value = value;
  }
}

class AttributeArray {
  constructor(obj) {
    const keys = Object.keys(obj);
    this.set = keys.map((k) => {
      return new Attribute(k, obj[k]);
    });
  }

  addNew() {
    this.set = [...this.set, new Attribute(null, null)];
  }

  removeByIndex(k) {
    this.set = [...this.set.filter((a, i) => i !== k)];
  }

  toObject() {
    let obj = {};
    this.set.forEach((s) => {
      if (s.key) {
        obj[s.key] = s.value;
      }
    });

    return obj;
  }
}

export default {
  name: "FormArrayValues",
  mixins: [FormMixin],
  components: {
    FormInput,
    FormGroup,
  },
  emits: ["update:modelValue", "change"],
  props: {
    append: {
      type: String,
    },
    prepend: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    bold: {
      type: Boolean,
      default: false,
    },
    label: String,
    modelValue: Object,
    autocomplete: {
      type: String,
      default: "on",
      validator: (val) => {
        return ["on", "off"].includes(val);
      },
    },
  },
  setup(props, { emit }) {
    const arrayOfItems = reactive(new AttributeArray(props.modelValue ?? {}));

    const addItem = () => {
      arrayOfItems.addNew();
    };

    const removeItem = (i) => {
      arrayOfItems.removeByIndex(i);
    };

    watch(
      () => arrayOfItems,
      (arr, prevArr) => {
        emit("update:modelValue", arr.toObject());
        emit("change", arr.toObject());
      },
      {
        deep: true,
      }
    );

    return { addItem, removeItem, arrayOfItems };
  },
};
</script>

<style lang="postcss"></style>
