<template>
  <div class="">
    <div class="text-lg font-bold mb-8">{{ folderRecord.id ? "Update" : "Create" }} Folder</div>
    <div class="">
      <!-- <hr class="mb-6" /> -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <form-select
          v-model="folderRecord.image_base_folder_id"
          :options="folderOptions"
          :noneLabel="'ROOT'"
          label="Parent Folder"
          class="mb-6"
        />
        <form-input
          v-model="folderRecord.order"
          placeholder="Order"
          label="Folder Order"
          type="number"
          class="mb-6"
        />
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <form-input v-model="folderRecord.name" placeholder="Name" label="Name" class="mb-6" />
        <form-input v-model="folderRecord.type" placeholder="Type" label="Type" class="mb-6" />
        <!-- <div class="text-sm italic text-gray-400 mb-6" v-if="folderRecord.choice == 'between'">
          When type is filled in, if this item is chosen in the selection process, any later
          selections will ignore items with a mismatched type.
        </div> -->
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div>
          <form-select
            v-model="folderRecord.choice"
            :options="choiceOptions"
            label="Folder Choice"
            class=""
          />
          <!-- <div class="text-sm italic text-gray-400 mb-6">
            AND: choose one of each from items in this folder<br />
            OR: choose one item from all items in this folder<br />
            BETWEEN: choose a configurable amount of items with weighted influence
          </div> -->
        </div>
        <div>
          <form-array-values
            v-model="folderRecord.between"
            label="Between Options"
            class=""
            v-if="folderRecord.choice == 'between'"
          />
          <!-- <div class="text-sm italic text-gray-400 mb-6" v-if="folderRecord.choice == 'between'">
            For key, enter a number to indicate have many items should be chosen, and for value enter a
            number to give that key a relative weighting among all other keys. <br /><br />E.g. entering
            [key: 0, value: 9] and also entering [key: 1, value: 1] would yield a 1 in 10 chance 1 item
            is chosen, and a 9 in 10 chance 0 items are chosen.
          </div> -->
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <form-input
          v-model="folderRecord.layer"
          placeholder="Layer"
          label="Layer Number"
          type="number"
          class="mb-6"
        />
        <form-input
          v-model="folderRecord.probability"
          placeholder="Probability"
          label="Probability"
          class="mb-6"
        />
        <!-- <div class="text-sm italic text-gray-400 mb-6" v-if="folderRecord.choice == 'between'">
          Probability indicates the chance out of 100 that this item, if "selected", will actually be
          added to a set.
        </div> -->
        <form-input
          v-model="folderRecord.weight"
          placeholder="Weight"
          label="Weight"
          class="mb-6"
        />
        <!-- <div class="text-sm italic text-gray-400 mb-6" v-if="folderRecord.choice == 'between'">
          When the parent folder choice is "and", weight can indicate a chance for this item to be
          selected relative to other weight values of items in the same parent folder. When all
          weights are empty for a folder, all items have equal weight.
        </div> -->
      </div>

      <hr class="mb-4" />
      <form-array-values v-model="folderRecord.attributesn" label="Attributes" class="mb-6" />
      <!-- <div class="text-sm italic text-gray-400 mb-6" v-if="folderRecord.choice == 'between'">
        Attributes are key-value pairs that stack up to be the basis of metadata for outputs, or can
        be used to restrict combinations (see "Restrictions") below.
      </div> -->

      <hr class="mb-4" />
      <form-array-values v-model="folderRecord.restrictions" label="Restrictions" class="mb-6" />
      <!-- <div class="text-sm italic text-gray-400 mb-6" v-if="folderRecord.choice == 'between'">
        Restrictions are key-value pairs that limit later selections based on attributes. E.g.
        setting a restriction to [color, red] woud limit any later selections with a color attribute
        to those that are "red". Value can be empty, and if prefixed with "!", value will compare
        against "NOT the value"
      </div> -->
      <hr class="mb-4" />
      <form-array-values v-model="folderRecord.modifiers" label="Modifiers" class="mb-6" />

      <hr class="mb-4" />
    </div>
    <div class="text-center">
      <button @click="save" class="btn">Save</button>
    </div>
    <!-- 
        $folder->name = $request->name;
        $folder->image_base_id = $request->image_base_id;
        $folder->image_base_folder_id = $request->image_base_folder_id;
        $folder->probability = $request->probability;
        $folder->layer = $request->layer;
        $folder->weight = $request->weight;
        $folder->type = $request->type;
        $folder->restrictions = $request->restrictions;
        $folder->attributes = $request->attributes;
        $folder->choice = $request->choice;
        $folder->between = $request->between;
         -->
  </div>
</template>

<script>
import { computed, ref, reactive, watch, toRaw } from "vue";
import FormInput from "@/components/FormInput";
import Folder from "@/classes/Folder";
import FormSelect from "./FormSelect.vue";
import FormArrayValues from "./FormArrayValues.vue";
import store from "@/store";

export default {
  name: "FolderForm",
  components: {
    FormInput,
    FormSelect,
    FormArrayValues,
  },
  props: {
    folder: [Folder, Object],
    folderOptions: Array,
    baseId: [String, Number],
  },
  emits: ["saved"],
  setup(props, { emit }) {
    const choiceOptions = [
      {
        value: "and",
        label: "And",
      },
      {
        value: "or",
        label: "Or",
      },
      {
        value: "between",
        label: "Between",
      },
    ];
    const folderRecord = reactive(new Folder(props.folder));

    const save = async () => {
      let data = {
        ...toRaw(folderRecord),
        base_id: props.baseId,
      };

      try {
        let req = await store.dispatch("team/createBaseFolder", data);
        emit("saved", req);
      } catch (e) {
        console.log("error creating: ", e);
        store.dispatch("toasts/error", ["Could not create/update folder", e]);
      }

      // await createFolder
    };

    return { save, folderRecord, choiceOptions };
  },
};
</script>

<style lang="postcss"></style>
