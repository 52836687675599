<template>
  <div class="block md:hidden">
    <div class="navigation flex justify-between bg-white p-4 relative">
      <div class="flex-shrink-0 relative" style="width: 40px">
        <nav-hamburger v-model="isOpen" class="relative z-30" />
      </div>
      <div>
        <img src="https://cdn.hashku.com/assets/hashku-logotype.png" style="height: 30px" />
      </div>
      <div class="flex-shrink-0" style="width: 40px"></div>
    </div>
    <div
      class="
        mobile-nav
        fixed
        h-full
        w-full
        top-0
        left-0
        bg-white
        py-4
        overflow-y-auto
        transform
        z-40
        transition-all
        lg:w-1/3
        shadow-lg
      "
      :class="{ 'opened translate-x-0': isOpen }"
      style="max-width: 250px"
    >
      <div class="ml-4"><nav-hamburger v-model="isOpen" class="relative z-50" /></div>
      <div class="flex flex-col pt-4 items-center">
        <router-link
          class="text-lg mb-0 border-t w-full p-4 text-center"
          :to="{ name: 'Home' }"
          @click="isOpen = false"
          v-if="isLoggedIn"
        >
          Home
        </router-link>
        <router-link
          class="text-lg mb-0 border-t w-full p-4 text-center border-b"
          :to="logoutLink"
          @click="isOpen = false"
          v-if="isLoggedIn"
        >
          Log Out
        </router-link>
      </div>
    </div>
    <div
      @click="isOpen = false"
      class="fixed h-full w-full top-0 left-0 z-20 mobile-nav"
      :class="{ 'opened translate-x-0': isOpen }"
    ></div>
  </div>
  <div class="hidden md:block bg-white">
    <div class="container pt-4 pb-6">
      <div class="flex items-end justify-between">
        <div class="left flex items-end">
          <div>
            <router-link :to="{ name: 'Home' }">
              <img src="https://cdn.hashku.com/assets/hashku-logotype.png" style="height: 40px" />
            </router-link>
          </div>
          <div class="ml-8">
            <router-link
              :to="{ name: 'Home' }"
              class="text-blue font-medium hover:underline"
              v-if="isLoggedIn"
            >
              Home
            </router-link>
          </div>
          <div class="ml-6"></div>
        </div>
        <div class="right">
          <router-link
            :to="{ name: 'Logout' }"
            class="text-blue font-medium hover:underline"
            v-if="isLoggedIn"
          >
            Log Out
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import NavHamburger from "@/components/NavHamburger.vue";
import { logout } from "@/request/definitions";
import constants from "@/constants";
import store from "@/store";

export default {
  name: "Navigation",
  components: {
    NavHamburger,
  },
  props: {
    number: [Number, String],
  },
  setup(props) {
    const isLoggedIn = computed(() => {
      return store.getters["auth/isLoggedIn"];
    });

    const isOpen = ref(false);
    const logoutLink = {
      name: constants.LOGOUT_ROUTE,
    };

    return { isOpen, logoutLink, isLoggedIn };
  },
};
</script>

<style lang="postcss">
.mobile-nav {
  transform: translateX(-105%);
}
.mobile-nav.opened {
  transform: translateX(0);
}
</style>
