<template>
  <div class="flex flex-col">
    <slot name="checkbox" />
    <slot name="radio" />
    <slot name="label">
      <label
        :for="inputId"
        v-if="label"
        :class="[
          labelClass,
          {
            'invalid-text': errors && errors.length,
            'valid-text': successes && successes.length,
          },
        ]"
        class="mb-1 text-rocks text-sm"
        v-html="label"
      ></label>
    </slot>
    <div
      class="input-group flex flex-row"
      :class="{ invalidinput: errors && errors.length, validinput: successes && successes.length }"
    >
      <slot></slot>
    </div>
    <slot name="append-content"></slot>
    <div class="valid-feedback valid-text" v-if="successes && success.length">
      <div v-for="(success, s) in successes" :key="s">
        {{ success }}
      </div>
    </div>
    <div class="invalid-feedback invalid-text" v-if="errors && errors.length">
      <div v-for="(error, e) in errors" :key="e">
        {{ error }}
      </div>
    </div>
    <small v-if="description" class="form-text text-muted">{{ description }}</small>
  </div>
</template>

<script>
export default {
  props: {
    description: String,
    inputId: String,
    label: String,
    labelClass: String,
    errors: [Array, Boolean],
    successes: [Array, Boolean],
    validated: Boolean,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="postcss">
.form-group {
  @apply mb-4;
}

.form-group label {
  @apply text-gray text-sm mb-2 block;
}

.form-control {
  @apply flex w-full outline-none px-2 py-2 bg-none bg-transparent overflow-hidden placeholder-plcgray;
}

.input-group {
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  @apply border border-black border-solid rounded bg-eggshell overflow-hidden items-center;
}

.input-group.disabled {
  @apply bg-eggshell;
}

.input-group.disabled .form-control {
  @apply text-plcgray;
}

.invalid-feedback,
.valid-feedback {
  @apply text-xs block mt-2;
}

.invalid-text {
  @apply text-red-500;
}

.valid-text {
  @apply text-green-500;
}

.invalidinput {
  @apply border-red-500;
}

.validinput {
  @apply border-green-500;
}

.is-valid {
  @apply text-green-500;
}

.is-invalid {
  @apply text-red-500;
}
</style>
