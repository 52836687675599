<template>
  <div class="home pt-4">
    <div class="flex flex-col max-w-xl mx-auto px-8">
      <div class="text-blue text-2xl py-4 text-center font-medium">Image Files</div>
      <div class="shadow bg-white rounded overflow-hidden mb-8">
        <div class="p-4 text-center">
          <div class="mb-4 font-semibold text-blue">Image Files</div>
          <div class="mb-0">
            <router-link
              :to="{
                name: 'Team-Collection-Images',
                params: {
                  team_slug: team.slug,
                  collection_slug: collection.slug,
                },
              }"
              class="btn btn"
            >
              Go To Image Files
            </router-link>
          </div>
        </div>
      </div>

      <div class="text-blue text-2xl py-4 text-center font-medium">All Base Outputs</div>
      <div class="shadow bg-white rounded overflow-hidden mb-8">
        <div class="p-4 text-center">
          <div class="mb-4 font-semibold text-blue">View Only of Outputs for All Bases</div>
          <div class="mb-0">
            <router-link
              :to="{
                name: 'Team-Collection-Outputs',
                params: {
                  team_slug: team.slug,
                  collection_slug: collection.slug,
                },
              }"
              class="btn btn"
            >
              Go To Outputs
            </router-link>
          </div>
        </div>
      </div>

      <div class="text-blue text-2xl py-4 text-center font-medium">
        Image Bases
        <button
          @click="() => (addModal = true)"
          class="bg-blue text-white font-bold w-4 h-4 rounded-full hover:bg-blue-300 text-xs ml-2"
        >
          +
        </button>
      </div>
      <div class="shadow bg-white rounded overflow-hidden mb-8" v-for="(base, c) in bases" :key="c">
        <div class="p-4 text-center">
          <div class="mb-4 font-semibold text-blue">
            {{ base.name }}
            <button
              @click="() => editBase(base)"
              class="
                bg-blue
                text-white
                font-bold
                w-4
                h-4
                rounded-full
                hover:bg-blue-300
                text-xs
                ml-2
              "
            >
              e
            </button>
          </div>
          <div class="mb-0">
            <router-link
              :to="{
                name: 'Team-Collection-Base-Home',
                params: {
                  team_slug: team.slug,
                  collection_slug: collection.slug,
                  base_id: base.id,
                },
              }"
              class="btn btn"
            >
              Go To Base
            </router-link>
          </div>
        </div>
      </div>

      <div class="text-blue text-2xl py-4 text-center font-medium">All Sets CSV Export</div>
      <div class="shadow bg-white rounded overflow-hidden mb-8">
        <div class="p-4 text-center">
          <div class="mb-4 font-semibold text-blue">Export CSV of All Sets and Attributes</div>
          <div class="mb-0">
            <button type="button" @click="getCollectionSetsExport" class="btn btn">Download</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal v-if="addModal" :cancel="closeModal">
    <form @submit.prevent="create" class="flex flex-col items-center justify-center pt-2">
      <div class="mb-4">
        <form-input v-model="createForm.name" label="Name" :disabled="loading" />
      </div>
      <div><button type="submit" class="btn" :disabled="loading">Submit</button></div>
    </form>
  </modal>
  <modal v-if="editModal.base_id" :cancel="closeEditModal">
    <form @submit.prevent="edit" class="flex flex-col items-center justify-center pt-2">
      <div class="mb-4">
        <form-input v-model="editModal.name" label="Name" :disabled="editLoading" />
      </div>
      <div><button type="submit" class="btn" :disabled="editLoading">Submit</button></div>
    </form>
  </modal>
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted, reactive } from "vue";

import Navigation from "@/components/Navigation.vue";
import Modal from "@/components/Modal.vue";
import FormInput from "@/components/FormInput.vue";

export default {
  name: "Team-Collection-Home",
  components: {
    Navigation,
    Modal,
    FormInput,
  },
  setup() {
    const loading = ref(false);
    const bases = computed(() => {
      return store.state.team.collection?.bases;
    });

    const collection = computed(() => {
      return store.state.team.collection;
    });

    const team = computed(() => {
      return store.state.team.item;
    });

    const addModal = ref(false);
    const createForm = reactive({
      name: null,
    });

    const closeModal = () => {
      createForm.name = null;
      addModal.value = false;
    };
    const create = () => {
      loading.value = true;
      store
        .dispatch("team/createBase", createForm)
        .then(() => {
          closeModal();
        })
        .catch((e) => {
          store.dispatch("toasts/error", "Could not add base");
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getCollectionSetsExport = async () => {
      await store.dispatch("team/getCollectionSetsExport");
    };

    const editModal = reactive({
      id: null,
      name: null,
    });

    const closeEditModal = () => {
      if (editLoading.value) {
        return;
      }
      editModal.base_id = null;
      editModal.name = null;
    };

    const editBase = (base) => {
      editModal.base_id = base.id;
      editModal.name = base.name;
    };

    const editLoading = ref(false);

    const edit = () => {
      editLoading.value = true;
      store
        .dispatch("team/updateBase", editModal)
        .then(() => {
          editModal.base_id = null;
          editModal.name = null;
        })
        .catch((e) => {
          store.dispatch("toasts/error", "Could not add base");
        })
        .finally(() => {
          editLoading.value = false;
        });
    };

    onUnmounted(() => {});

    return {
      bases,
      collection,
      team,
      addModal,
      loading,
      create,
      createForm,
      closeModal,
      closeEditModal,
      editModal,
      editLoading,
      editBase,
      edit,
      getCollectionSetsExport,
    };
  },
};
</script>
