<template>
  <div class="home pt-4">
    <div class="text-blue text-2xl py-4 text-center font-medium">{{ base.name }}</div>
    <div class="container">
      <div class="grid grid-cols-2 gap-4">
        <div class="shadow bg-white rounded overflow-hidden text-center p-4">
          <div class="mb-4">
            Files &amp; Folders: used for generating sets from input files and folder
          </div>
          <router-link
            :to="{
              name: 'Team-Collection-Base-Files',
              params: {
                team_slug: team.slug,
                collection_slug: collection.slug,
                base_id: base_id,
              },
            }"
            class="btn btn"
          >
            Go To Files &amp; Folders
          </router-link>
        </div>
        <div class="shadow bg-white rounded overflow-hidden text-center p-4">
          <div class="mb-4">Sets are generative or manually created lists of image parts.</div>
          <router-link
            :to="{
              name: 'Team-Collection-Base-Sets',
              params: {
                team_slug: team.slug,
                collection_slug: collection.slug,
                base_id: base_id,
              },
            }"
            class="btn btn"
          >
            Go To Sets
          </router-link>
        </div>
        <div class="shadow bg-white rounded overflow-hidden text-center p-4">
          <div class="mb-4">Outputs are final files</div>
          <router-link
            :to="{
              name: 'Team-Collection-Base-Outputs',
              params: {
                team_slug: team.slug,
                collection_slug: collection.slug,
                base_id: base_id,
              },
            }"
            class="btn btn"
          >
            Go To Outputs
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted } from "vue";

import Navigation from "@/components/Navigation.vue";
import router from "@/router";

export default {
  name: "Team-Collection-Base-Home",
  components: {
    Navigation,
  },
  props: {
    base_id: String,
  },
  setup(props) {
    const base = computed(() => {
      return store.getters["team/getBase"](props.base_id);
    });

    const collection = computed(() => {
      return store.state.team.collection;
    });

    const team = computed(() => {
      return store.state.team.item;
    });

    onMounted(() => {});

    onUnmounted(() => {});

    return { base, collection, team };
  },
};
</script>
