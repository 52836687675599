<template>
  <div
    class="text-white flex items-center justify-center text-xs font-bold rounded px-1"
    :class="[colorClass]"
  >
    {{ name }}
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "Tag",
  props: {
    name: [Number, String],
    color: String,
  },
  setup(props) {
    const colorClass = computed(() => {
      switch (props.color) {
        case "red":
          return "bg-red-400";
        case "black":
          return "bg-black";
        case "blue":
          return "bg-blue";
        case "gray":
          return "bg-gray-500";
        case "orange":
          return "bg-orange";
        case "yellow":
          return "bg-yellow-500";
        case "purple":
          return "bg-purple-600";
        case "pink":
          return "bg-pink-600";
        default:
          return "bg-gray-500";
      }
    });

    return {
      colorClass,
    };
  },
};
</script>

<style lang="postcss"></style>
