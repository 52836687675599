class BaseChild {
  constructor(data = {}) {
    this.id = data?.id;
    this.image_base_id = data?.image_base_id ?? null;
    this.image_base_folder_id = data?.image_base_folder_id ?? null;
    this.name = data?.name;
    this.probability = data?.probability;
    this.order = data?.order;
    this.layer = data?.layer;
    this.weight = data?.weight;
    this.type = data?.type;
    this.restrictions = data?.restrictions ?? [];
    this.attributesn = data?.attributesn ?? [];
    this.modifiers = data?.modifiers ?? [];
  }
}

export default BaseChild;
