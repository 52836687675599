<template>
  <FormGroup :label="label" :inputId="idCalc" :errors="errorsCalc" :successes="successes">
    <div v-if="prepend" class="input-group-prepend">
      <span class="input-group-text">{{ prepend }}</span>
    </div>
    <slot name="prepend-input"></slot>
    <input
      class="form-control"
      :class="{ 'is-invalid': errorsCalc, 'is-valid': successes, 'font-bold': bold }"
      :data-test="testId"
      :id="idCalc"
      :name="name"
      :placeholder="placeholder"
      :type="type"
      :disabled="disabled"
      :value="modelValue"
      @input="change"
      :autocomplete="autocomplete"
    />
    <div v-if="append" class="input-group-append">
      <span class="input-group-text">{{ append }}</span>
    </div>
    <slot name="append-input"></slot>
  </FormGroup>
</template>

<script>
import FormGroup from "@/components/FormGroup";
import FormMixin from "@/components/FormMixin";

export default {
  components: {
    FormGroup,
  },
  mixins: [FormMixin],
  emits: ["update:modelValue", "change"],
  props: {
    append: {
      type: String,
    },
    prepend: {
      type: String,
    },

    type: {
      type: String,
      default: "text",
    },
    bold: {
      type: Boolean,
      default: false,
    },
    label: String,
    modelValue: [String, Number],
    autocomplete: {
      type: String,
      default: "on",
      validator: (val) => {
        return ["on", "off"].includes(val);
      },
    },
  },
  setup(props, { emit }) {
    const change = (val) => {
      emit("update:modelValue", val.target.value);
      emit("change", val);
    };
    return { change };
  },
};
</script>

<style lang="postcss"></style>
