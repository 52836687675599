<template>
  <div class="home pt-4">
    <div class="container">
      <div class="mb-2 flex items-center" v-if="outputMetadata">
        <form-select
          v-model="tempMetadata"
          :options="metadataMenu"
          @change="toggleMetadata"
          noneLabel="Filter by Attribute"
          class="mr-4"
        />
        <div class="flex flex-wrap flex-grow">
          <template v-for="(selected, s) in selectedMetadatas" :key="s">
            <button
              v-for="(val, v) in selected.values"
              :key="v"
              @click="toggleMetadata(`${selected.key}|-|${val}`)"
              class="text-xs bg-orange text-white px-2 py-1 rounded-full mr-1 mb-1"
            >
              {{ selected.key }}: {{ val }} (X)
            </button>
          </template>
        </div>
      </div>
      <div class="mb-4 flex items-center text-sm text-gray-500" v-if="outputMetadata">
        Current selections:
        {{ Math.round((outputMetadata.count / outputMetadata.total) * 1000) / 10 }}% ({{
          outputMetadata.count
        }}
        / {{ outputMetadata.total }})
      </div>
      <div v-if="outputs?.outputs">
        <pagination
          :currentPage="outputs?.current_page"
          :totalPages="outputs?.total_pages"
          @page="refreshData"
        />
        <div class="flex justify-center flex-wrap items-stretch">
          <output-item
            v-for="output in outputs.outputs"
            :key="output.id"
            :output="output"
            @editOutput="editOutput"
            @saveOutput="toggleBaseOutputSave"
          />
        </div>
        <pagination
          :currentPage="outputs?.current_page"
          :totalPages="outputs?.total_pages"
          @page="refreshData"
        />
      </div>
    </div>
  </div>
  <modal v-if="addModal" :cancel="closeModal">
    <form @submit.prevent="upload" class="flex flex-col items-center justify-center pt-2">
      <div class="border mb-4"><input type="file" @change="setFile" /></div>
      <div><button type="submit" class="btn">Submit</button></div>
    </form>
  </modal>
  <modal v-if="editingOutput" :cancel="cancelOutput">
    <div class="flex items-center justify-between mb-4">
      <button
        @click="goToPreviousOutput"
        type="button"
        class="btn btn-sm btn-outline"
        :disabled="previousOutputIndex < 0"
      >
        &lt; Previous
      </button>
      <button
        @click="goToNextOutput"
        type="button"
        class="btn btn-sm btn-outline"
        :disabled="nextOutputIndex < 0"
      >
        Next &gt;
      </button>
    </div>
    <div class="mb-4 font-bold">{{ editingOutput.display_name }}</div>
    <div class="mb-4 mx-auto" style="max-width: 500px">
      <img :src="outputImageUrl(editingOutput.image_base_id, editingOutput.id)" />
    </div>
    <div>
      <div class="font-bold mb-2">ID: {{ editingOutput.id }}</div>
      <div class="font-bold mb-2">{{ editingOutput.display_name }}</div>
      <div>
        <div v-for="(meta, m) in editingOutput.metadata" :key="m">
          <span class="font-bold">{{ m }}:</span> {{ meta }}
        </div>
      </div>
    </div>
  </modal>
  <loader v-if="loading" />
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted, reactive, unref } from "vue";
import Navigation from "@/components/Navigation.vue";
import router from "@/router";
import Modal from "@/components/Modal.vue";
import FormInput from "@/components/FormInput.vue";
import FormSelect from "@/components/FormSelect.vue";
import Pagination from "@/components/Pagination.vue";
import OutputItem from "@/components/OutputItemView.vue";
import Loader from "@/components/Loader.vue";
import FormArrayValues from "@/components/FormArrayValues.vue";

export default {
  name: "Team-Collection-Base-Files",
  components: {
    Navigation,
    Modal,
    FormInput,
    FormSelect,
    OutputItem,
    Pagination,
    FormArrayValues,
    Loader,
  },
  props: {
    // base_id: String,
  },
  setup(props) {
    const base = ref(null);
    const loading = ref(true);
    const outputs = ref(null);
    const addModal = ref(false);
    const collection = computed(() => {
      return store.state.team.collection;
    });

    const team = computed(() => {
      return store.state.team.item;
    });

    const uploadFile = ref(null);
    const setFile = (event) => {
      uploadFile.value = event.target.files[0];
    };

    const loadingUpload = ref(false);

    const outputImageUrl = (base_id, id) => {
      return store.getters["team/outputUrl"](base_id, id);
    };

    const editForm = reactive({
      name: null,
      metadata: [],
    });
    const editingOutput = ref(null);
    const editOutput = (data) => {
      editingOutput.value = data;
    };
    const cancelOutput = () => {
      editingOutput.value = null;
    };
    const updateOutput = () => {
      loading.value = true;
      store
        .dispatch("team/updateBaseOutput", {
          base_id: props.base_id,
          id: editingOutput.value.id,
          name: editingOutput.value.name,
          metadata: editingOutput.value.metadata,
        })
        .then(() => {
          refreshData();
          cancelOutput();
        })
        .catch((e) => {
          store.dispatch("toasts/error", ["Could not update", e]);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const closeModal = () => {
      if (loadingUpload.value) {
        return;
      }
      uploadFile.value = null;
      addModal.value = false;
    };

    const upload = () => {
      loading.value = true;
      store
        .dispatch("team/uploadBaseOutput", {
          base_id: props.base_id,
          file: uploadFile.value,
        })
        .then((data) => {
          refreshData();
          addModal.value = false;
          uploadFile.value = null;
        })
        .catch((e) => {
          store.dispatch("toasts/error", ["Could not upload", e]);
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const outputMetadata = ref(null);
    const refreshData = async (page = null) => {
      loading.value = true;
      let data = await store.dispatch("team/getCollectionOutputs", {
        page,
        metadata: metadataForSearch.value,
      });
      outputs.value = data;
      loading.value = false;
      outputMetadata.value = await store.dispatch("team/getCollectionOutputsMetadata", {
        metadata: metadataForSearch.value,
      });
    };

    const toggleBaseOutputSave = () => {};
    const selectedMetadatas = ref([]);
    const tempMetadata = ref(null);
    const toggleMetadata = (attrString = null) => {
      if (tempMetadata.value || attrString) {
        let attrArr = tempMetadata.value
          ? tempMetadata.value.split("|-|")
          : attrString.split("|-|");
        let attr = {
          key: attrArr[0],
          value: attrArr[1],
        };
        let copySelected = unref([...selectedMetadatas.value]);

        let foundAttr = copySelected.findIndex((s) => s.key == attr.key);

        if (foundAttr > -1) {
          let foundVal = copySelected[foundAttr].values.indexOf(attr.value);

          if (foundVal < 0) {
            copySelected[foundAttr].values = [...copySelected[foundAttr].values, attr.value];
          } else {
            copySelected[foundAttr].values = [
              ...copySelected[foundAttr].values.filter((a) => a != attr.value),
            ];
          }
        } else {
          copySelected = [
            ...copySelected,
            {
              key: attr.key,
              values: [attr.value],
            },
          ];
        }
        console.log({ copySelected });
        selectedMetadatas.value = [...copySelected];
        tempMetadata.value = null;
        refreshData();
      }
    };
    const metadataMenu = computed(() => {
      if (!outputMetadata.value?.metadata) {
        return null;
      }
      let keys = Object.keys(outputMetadata.value?.metadata);
      let menu = [];
      keys.forEach((k) => {
        outputMetadata.value.metadata[k].forEach((a) => {
          let stats = outputMetadata.value.properties[`${k}-${a}`];
          if (
            !selectedMetadatas.value?.find((s) => {
              return s.values.find((v) => v == a);
            })
          ) {
            menu = [
              ...menu,
              {
                value: `${k}|-|${a}`,
                label: `${k}: ${a} (${stats})`,
              },
            ];
          }
        });
      });
      return menu;
    });
    const metadataForSearch = computed(() => {
      let newObj = {};

      selectedMetadatas.value?.forEach((a) => {
        if (a?.values?.length) {
          newObj[a.key] = a.values;
        }
      });

      return newObj;
    });

    const resetOutputAttributes = ref(false);
    const previousOutputIndex = computed(() => {
      const index = outputs.value?.outputs?.findIndex((s) => s.id === editingOutput.value?.id);
      return index > 0 ? index - 1 : -1;
    });
    const goToPreviousOutput = () => {
      if (previousOutputIndex.value > -1) {
        resetOutputAttributes.value = true;
        editingOutput.value = outputs.value?.outputs?.[previousOutputIndex.value];
        setTimeout(() => {
          resetOutputAttributes.value = false;
        }, 1);
      }
    };

    const nextOutputIndex = computed(() => {
      const index = outputs.value?.outputs?.findIndex((s) => s.id === editingOutput.value?.id);
      return index > -1 && index + 1 < outputs.value?.outputs?.length ? index + 1 : -1;
    });
    const goToNextOutput = () => {
      if (nextOutputIndex.value > -1) {
        resetOutputAttributes.value = true;
        editingOutput.value = outputs.value?.outputs?.[nextOutputIndex.value];
        setTimeout(() => {
          resetOutputAttributes.value = false;
        }, 1);
      }
    };

    onMounted(() => {
      refreshData();
    });

    onUnmounted(() => {});

    return {
      resetOutputAttributes,
      previousOutputIndex,
      goToPreviousOutput,
      nextOutputIndex,
      goToNextOutput,
      loading,
      team,
      collection,
      base,
      outputs,
      addModal,
      uploadFile,
      setFile,
      upload,
      outputImageUrl,
      closeModal,
      editingOutput,
      editOutput,
      cancelOutput,
      editForm,
      updateOutput,
      toggleBaseOutputSave,
      refreshData,
      metadataForSearch,
      metadataMenu,
      toggleMetadata,
      selectedMetadatas,
      tempMetadata,
      outputMetadata,
    };
  },
};
</script>
