<template>
  <div class="shadow m-2 w-60 rounded overflow-hidden cursor-pointer" @click.stop="editOutput">
    <div class="text-lg flex flex-col items-center">
      <div class="w-60"><img :src="outputImageUrl(output.image_base_id, output.id)" /></div>
      <div class="p-2">
        <div class="text-center mr-4 text-sm font-semibold">
          [ID: {{ output.id }}] {{ output?.display_name }}
        </div>
        <div class="text-center">
          <div class="text-xs">
            {{ metaTextList }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import Tag from "@/components/Tag.vue";
import store from "@/store";

export default {
  name: "OutputItem",
  components: {
    Tag,
  },
  props: {
    output: Object,
  },
  emits: ["editOutput", "deleteOutput", "saveOutput"],
  setup(props, { emit }) {
    const editOutput = (event) => {
      emit("editOutput", props.output);
    };
    const deleteOutput = () => {
      emit("deleteOutput", props.output);
    };
    const saveOutput = () => {
      emit("saveOutput", props.output);
    };

    const metaList = computed(() => {
      if (!props.output?.metadata) {
        return [];
      }
      let keys = Object.keys(props.output.metadata);

      return keys.map((k) => {
        return {
          value: props.output.metadata[k],
          key: k,
        };
      });
    });

    const metaOrigList = computed(() => {
      if (!props.output?.metadata_original) {
        return [];
      }
      let keys = Object.keys(props.output.metadata_original);

      return keys.map((k) => {
        return {
          value: props.output.metadata_original[k],
          key: k,
        };
      });
    });

    const metaTextList = computed(() => {
      return metaList.value?.map((m) => `${m.key}: ${m.value}`).join(", ");
    });

    const metaOrigTextList = computed(() => {
      return metaOrigList.value?.map((m) => `${m.key}: ${m.value}`).join(", ");
    });

    const outputImageUrl = (base_id, id) => {
      return store.getters["team/outputUrl"](base_id, id);
    };

    return {
      editOutput,
      deleteOutput,
      metaList,
      metaTextList,
      saveOutput,
      metaOrigList,
      metaOrigTextList,
      outputImageUrl,
    };
  },
};
</script>

<style lang="postcss"></style>
