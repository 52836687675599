<template>
  <div class="">
    <router-link :to="{ name: 'Team-Home', params: { team_slug: team.slug } }" v-if="team">
      <div v-if="team" class="text-center p-2 bg-gray-200">
        {{ team.name }}
      </div>
    </router-link>
    <router-view v-if="!loading" />
  </div>
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted } from "vue";

import Navigation from "@/components/Navigation.vue";
import router from "@/router";

export default {
  name: "Team",
  components: {
    Navigation,
  },
  props: {
    team_slug: String,
  },
  setup(props) {
    const team = computed(() => {
      return store.state.team.item;
    });

    const loading = ref(true);

    onMounted(() => {
      store.dispatch("loading/setGlobal", true);
      store
        .dispatch("team/getTeam", props.team_slug)
        .then((data) => {
          loading.value = false;
        })
        .catch((e) => {
          store.dispatch("toasts/error", "Could not load team");
          router.push({ name: "Home" });
        })
        .finally(() => {
          store.dispatch("loading/setGlobal", false);
        });
    });

    onUnmounted(() => {});

    return { team, loading };
  },
};
</script>
