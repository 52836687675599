<template>
  <div class="home pt-4 container">
    <div class="flex items-center justify-between mb-6">
      <div class="left">
        <router-link
          :to="{
            name: 'Team-Collection-Base-Files',
            params: {
              team_slug: team.slug,
              collection_slug: collection.slug,
              base_id: base_id,
            },
          }"
          class="btn btn-sm btn-outline mr-2"
        >
          Go To Files
        </router-link>
        <button class="btn btn-sm mr-2" @click="addSet">Add Set</button>
        <button @click="deleteAllBaseSets" class="btn btn-danger btn-sm mr-2">Delete All</button>
        <button @click="keepAllBaseSets" class="btn btn-success btn-sm">Keep All</button>
      </div>
      <div class="right">
        <button @click="downloadSets" class="btn btn-sm btn mr-2">Download Summary</button>
        <router-link
          :to="{
            name: 'Team-Collection-Base-Outputs',
            params: {
              team_slug: team.slug,
              collection_slug: collection.slug,
              base_id: base_id,
            },
          }"
          class="btn btn-sm btn-outline mr-2"
        >
          Go To Outputs
        </router-link>
      </div>
    </div>
    <div class="mb-2 flex items-center justify-between" v-if="setAttributes">
      <div class="flex items-center flex-grow">
        <div>
          <form-select
            v-model="tempAttribute"
            :options="attributeMenu"
            @change="toggleAttribute"
            noneLabel="Filter by Attribute"
            class="mr-4"
          />
        </div>
        <div class="flex flex-wrap flex-grow">
          <template v-for="(selected, s) in selectedAttributes" :key="s">
            <button
              v-for="(val, v) in selected.values"
              :key="v"
              @click="toggleAttribute(`${selected.key}|-|${val}`)"
              class="text-xs bg-orange text-white px-2 py-1 rounded-full mr-1 mb-1"
            >
              {{ selected.key }}: {{ val }} (X)
            </button>
          </template>
        </div>
      </div>
      <div class="">
        <button
          class="btn btn-sm btn-outline mr-2"
          type="button"
          @click="generateAllSetsOutput"
          v-if="teamRoleIsAdmin"
        >
          Generate All Outputs
        </button>
        <button class="btn btn-sm btn-outline" type="button" @click="deleteDuplicateBaseSets">
          Remove Duplicates
        </button>
      </div>
    </div>
    <div class="mb-4 flex items-center text-sm text-gray-500" v-if="setAttributes">
      Current selections:
      {{ Math.round((setAttributes.count / setAttributes.total) * 1000) / 10 }}% ({{
        setAttributes.count
      }}
      / {{ setAttributes.total }})
    </div>
    <div v-if="setsData?.sets">
      <set-item
        v-for="(set, s) in setsData.sets"
        :key="s"
        :set="set"
        @editSet="editSet"
        @saveSet="toggleBaseSetSave"
        @deleteSet="deleteBaseSet"
        @generateOutput="generateOutput"
      />
      <pagination
        :currentPage="setsData?.current_page"
        :totalPages="setsData?.total_pages"
        @page="refresh"
      />
    </div>
  </div>
  <modal v-if="selectedSet" :cancel="() => (selectedSet = null)">
    <div class="flex items-center justify-between mb-4">
      <button
        @click="goToPreviousSet"
        type="button"
        class="btn btn-sm btn-outline"
        :disabled="previousSetIndex < 0"
      >
        &lt; Previous
      </button>
      <button
        @click="goToNextSet"
        type="button"
        class="btn btn-sm btn-outline"
        :disabled="nextSetIndex < 0"
      >
        Next &gt;
      </button>
    </div>
    <div class="flex-grow relative">
      <div class="relative border mx-auto w-full mb-6" style="max-width: 500px">
        <img :src="require('@/assets/square.png')" class="w-full relative" />
        <img
          :src="imageUrl(file?.file?.id)"
          v-for="(file, f) in selectedSet.files"
          :key="f"
          class="absolute top-0"
          :style="{ 'z-index': file?.parsed?.layer }"
        />
      </div>
      <!-- <div>{{ selectedSet.files }}</div> -->
      <div>
        <form-input v-model="selectedSet.name" label="Name" class="mb-4" />
        <!-- <form-select
          :options="fileMenu"
          v-model="tempFile"
          label="Select Files"
          @change="addAFile"
        /> -->
        <form-autocomplete
          @selected="addAFileAutocomplete"
          :options="fileMenu"
          label="Add A File"
          class="mb-6"
        />
      </div>
      <div class="flex flex-wrap mb-6" v-if="selectedSet?.files?.length">
        <div
          class="text-sm bg-gray-200 shadow flex items-center rounded-full px-2 py-1 mr-2 mb-2"
          v-for="(file, f) in selectedSet.files"
          :key="f"
        >
          <div class="">
            {{
              checkFolder(file?.image_base_folder_id)
                ? `${checkFolder(file?.image_base_folder_id)} - `
                : null
            }}{{ file?.name }}
          </div>
          <button
            class="text-xs w-4 h-4 cursor-pointer flex items-center justify-center"
            type="button"
            @click="removeFileFromSet(file)"
          >
            x
          </button>
        </div>
      </div>
      <div class="text-center">
        <button
          class="btn btn-outline btn-success mr-4"
          @click.stop="toggleBaseSetSave(selectedSet, true)"
          type="button"
          :class="{ 'btn-outline': selectedSet.saved_at }"
        >
          {{ selectedSet.saved_at ? "Don't Keep" : "Keep" }}
        </button>
        <button type="button" class="btn" @click="createBaseSet">Save</button>
      </div>
    </div>
  </modal>
  <loader v-if="loading" />
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted, unref } from "vue";
import Navigation from "@/components/Navigation.vue";
import Pagination from "@/components/Pagination.vue";
import Modal from "@/components/Modal.vue";
import router from "@/router";
import SetItem from "@/components/SetItem.vue";
import Folder from "@/classes/Folder";
import File from "@/classes/File";
import FormInput from "@/components/FormInput.vue";
import FormSelect from "@/components/FormSelect.vue";
import FormAutocomplete from "@/components/FormAutocomplete.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "Team-Collection-Base-Sets",
  components: {
    Navigation,
    Pagination,
    SetItem,
    Modal,
    FormInput,
    FormSelect,
    FormAutocomplete,
    Loader,
  },
  props: {
    base_id: String,
  },
  setup(props) {
    const base = computed(() => {
      return store.getters["team/getBase"](props.base_id);
    });
    const collection = computed(() => {
      return store.state.team.collection;
    });
    const team = computed(() => {
      return store.state.team.item;
    });
    const teamRoleIsAdmin = computed(() => {
      return store.getters["auth/getTeamRole"](team.value.id) === "admin";
    });

    const setsData = ref(null);
    const loading = ref(true);
    const currentPage = ref(1);
    const totalPages = ref(null);
    const baseFiles = ref(null);
    const setAttributes = ref(null);
    const selectedAttributes = ref([]);

    const refresh = async (page = null) => {
      loading.value = true;
      let setReq = await store.dispatch("team/getBaseSets", {
        base_id: props.base_id,
        page,
        attributesn: attributesForSearch.value,
      });
      setsData.value = setReq;
      currentPage.value = setReq.current_page;
      totalPages.value = setReq.total_pages;
      setAttributes.value = await store.dispatch("team/getBaseSetsAttributes", {
        base_id: props.base_id,
        attributesn: attributesForSearch.value,
      });
      loading.value = false;
    };

    const deleteBaseSet = async (set) => {
      loading.value = true;

      try {
        await store.dispatch("team/deleteBaseSet", { base_id: props.base_id, id: set.id });
      } catch (e) {
        store.dispatch("toasts/error", ["Could not delete set", e]);
        return;
      }

      await refresh(currentPage.value);
      loading.value = false;

      store.dispatch("toasts/success", ["Deleted set"]);
    };
    const deleteAllBaseSets = async () => {
      loading.value = true;

      try {
        await store.dispatch("team/deleteAllBaseSets", { base_id: props.base_id });
      } catch (e) {
        store.dispatch("toasts/error", ["Could not delete all sets", e]);
        return;
      }

      await refresh();
      loading.value = false;

      store.dispatch("toasts/success", ["Deleted all sets"]);
    };

    const keepAllBaseSets = async () => {
      loading.value = true;

      try {
        await store.dispatch("team/keepAllBaseSets", { base_id: props.base_id });
      } catch (e) {
        store.dispatch("toasts/error", ["Could not keep all sets", e]);
        return;
      }

      await refresh();
      loading.value = false;

      store.dispatch("toasts/success", ["Kept all sets"]);
    };

    const toggleBaseSetSave = async (set, isSelected = false) => {
      loading.value = true;
      let newSelectedSet;
      try {
        newSelectedSet = await store.dispatch("team/toggleBaseSetSave", {
          base_id: props.base_id,
          id: set.id,
        });
      } catch (e) {
        store.dispatch("toasts/error", ["Could not toggle save set", e]);
        return;
      }

      if (isSelected) {
        selectedSet.value = newSelectedSet;
      }

      await refresh(currentPage.value);
      loading.value = false;

      store.dispatch("toasts/success", ["Toggled set"]);
    };

    onMounted(() => {
      refresh();
      store
        .dispatch("team/getBaseFiles", { base_id: props.base_id })
        .then((data) => {
          baseFiles.value = {
            folders: data.folders?.map((fol) => new Folder(fol)),
            files: data.files?.map((fil) => new File(fil)),
          };
        })
        .catch((e) => {
          console.log(e);
        });
    });

    onUnmounted(() => {});

    const imageUrl = (id) => {
      return store.getters["team/collectionUrl"](id);
    };

    const selectedSet = ref(null);
    const editSet = (set) => {
      selectedSet.value = { ...set };
    };

    const addSet = () => {
      selectedSet.value = {
        name: null,
        files: [],
      };
    };

    const previousSetIndex = computed(() => {
      const index = setsData.value?.sets?.findIndex((s) => s.id === selectedSet.value?.id);
      return index > 0 ? index - 1 : -1;
    });
    const goToPreviousSet = () => {
      if (previousSetIndex.value > -1) {
        selectedSet.value = { ...setsData.value?.sets?.[previousSetIndex.value] };
      }
    };

    const nextSetIndex = computed(() => {
      const index = setsData.value?.sets?.findIndex((s) => s.id === selectedSet.value?.id);
      return index > -1 && index + 1 < setsData.value?.sets?.length ? index + 1 : -1;
    });
    const goToNextSet = () => {
      if (nextSetIndex.value > -1) {
        selectedSet.value = { ...setsData.value?.sets?.[nextSetIndex.value] };
      }
    };

    const buildMenu = (folder, prefix = "") => {
      let val = folder?.files?.map((file) => {
        return {
          value: file.id,
          label: `${prefix}${folder.name} / ${file.name}`,
        };
      });

      let sub = folder.folders?.map((f) => buildMenu(f, `${prefix}${folder.name} / `));

      return [].concat.apply(val, sub);
    };
    const fileMenu = computed(() => {
      if (!baseFiles.value) {
        return;
      }
      return [].concat.apply(
        baseFiles.value?.files?.map((file) => {
          return {
            value: file.id,
            label: `${file.name}`,
          };
        }),
        baseFiles.value?.folders?.map((f) => buildMenu(f))
      );
    });

    const buildFiles = (folder) => {
      let val = folder?.files?.map((file) => {
        return {
          value: file,
          label: file.id,
        };
      });
      let sub = folder.folders?.map((f) => buildFiles(f));
      return [].concat.apply(val, sub);
    };
    const allFiles = computed(() => {
      if (!baseFiles.value) {
        return;
      }
      // let tempBaseFiles = [...baseFiles.value];
      return [].concat.apply(
        baseFiles.value?.files?.map((file) => {
          return {
            value: file,
            label: file.id,
          };
        }),
        baseFiles.value?.folders?.map((f) => buildFiles(f))
      );
    });

    const tempFile = ref(null);
    const addAFile = () => {
      let foundFile = allFiles.value.find((f) => f.label == tempFile.value);
      // console.log(tempFile.value, allFiles.value, foundFile);
      selectedSet.value.files = [...selectedSet.value.files, foundFile?.value];
      tempFile.value = null;
    };
    const addAFileAutocomplete = (val) => {
      let foundFile = allFiles.value.find((f) => f.label == val);
      // console.log(tempFile.value, allFiles.value, foundFile);
      selectedSet.value.files = [...selectedSet.value.files, foundFile?.value];
    };
    const removeFileFromSet = (file) => {
      if (selectedSet.value?.files?.length) {
        let fileIndex = selectedSet.value.files.findIndex((f) => f.id == file.id);
        let arrCopy = [...selectedSet.value.files];
        arrCopy.splice(fileIndex, 1);
        selectedSet.value.files = arrCopy;
      }
    };

    const tempAttribute = ref(null);
    const toggleAttribute = (attrString = null) => {
      // attr = {key: "", value: ""}
      if (tempAttribute.value || attrString) {
        let attrArr = tempAttribute.value
          ? tempAttribute.value.split("|-|")
          : attrString.split("|-|");
        let attr = {
          key: attrArr[0],
          value: attrArr[1],
        };
        let copySelected = unref([...selectedAttributes.value]);

        let foundAttr = copySelected.findIndex((s) => s.key == attr.key);

        if (foundAttr > -1) {
          let foundVal = copySelected[foundAttr].values.indexOf(attr.value);

          if (foundVal < 0) {
            copySelected[foundAttr].values = [...copySelected[foundAttr].values, attr.value];
          } else {
            copySelected[foundAttr].values = [
              ...copySelected[foundAttr].values.filter((a) => a != attr.value),
            ];
          }
        } else {
          copySelected = [
            ...copySelected,
            {
              key: attr.key,
              values: [attr.value],
            },
          ];
        }
        console.log({ copySelected });
        selectedAttributes.value = [...copySelected];
        tempAttribute.value = null;
        refresh();
      }
    };
    const attributeMenu = computed(() => {
      if (!setAttributes.value?.attributesn) {
        return null;
      }
      let keys = Object.keys(setAttributes.value?.attributesn);
      let menu = [];
      keys.forEach((k) => {
        setAttributes.value.attributesn[k].forEach((a) => {
          let stats = setAttributes.value.properties[`${k}-${a}`];
          if (
            !selectedAttributes.value?.find((s) => {
              return s.values.find((v) => v == a);
            })
          ) {
            menu = [
              ...menu,
              {
                value: `${k}|-|${a}`,
                label: `${k}: ${a} (${stats})`,
              },
            ];
          }
        });
      });
      return menu.sort((a, b) => (a.label > b.label ? 1 : -1));
    });
    const attributesForSearch = computed(() => {
      let newObj = {};

      selectedAttributes.value?.forEach((a) => {
        if (a?.values?.length) {
          newObj[a.key] = a.values;
        }
      });

      return newObj;
    });

    const buildFolder = (folder) => {
      let val = folder?.folders?.map((folder) => {
        return {
          value: folder.id,
          label: folder.name,
        };
      });
      let sub = folder.folders?.map((f) => buildFolder(f));
      return [].concat.apply(val, sub);
    };
    const allFolders = computed(() => {
      if (!baseFiles.value) {
        return;
      }
      // let tempBaseFiles = [...baseFiles.value];
      return [].concat.apply(
        baseFiles.value?.folders?.map((folder) => {
          return {
            value: folder.id,
            label: folder.name,
          };
        }),
        baseFiles.value?.folders?.map((f) => buildFolder(f))
      );
    });
    const checkFolder = (folderId) => {
      if (allFolders.value?.find((f) => f.value == folderId)) {
        return allFolders.value.find((f) => f.value == folderId)?.label;
      }

      return null;
    };
    const createBaseSet = async () => {
      loading.value = true;
      let set = selectedSet.value;
      try {
        await store.dispatch("team/createBaseSet", {
          base_id: props.base_id,
          id: set.id,
          name: set.name,
          image_file_ids: set.files?.map((i) => i.id),
        });
        store.dispatch("toasts/success", ["Created/updated set"]);
        if (!set.id) {
          selectedSet.value = null;
        }
      } catch (e) {
        store.dispatch("toasts/error", ["Could not save/update set", e]);
      }
      await refresh(currentPage.value);
      loading.value = false;
    };

    const downloadSets = async () => {
      loading.value = true;
      await store.dispatch("team/getBaseSetsExport", { base_id: props.base_id });
      loading.value = false;
    };

    const generateOutput = async (set) => {
      loading.value = true;
      await store.dispatch("team/generateSetOutput", { base_id: props.base_id, id: set.id });
      await refresh();
      loading.value = false;
    };

    const generateAllSetsOutput = async () => {
      loading.value = true;
      try {
        await store.dispatch("team/generateAllSetsOutput", { base_id: props.base_id });
        store.dispatch("toasts/success", [
          "Sets queued for output generation (could take several minutes)",
        ]);
      } catch (e) {
        store.dispatch("toasts/error", ["Could not queue all output generation", e]);
      }
      loading.value = false;
    };

    const deleteDuplicateBaseSets = async () => {
      loading.value = true;
      await store.dispatch("team/deleteDuplicateBaseSets", { base_id: props.base_id });
      store.dispatch("toasts/success", [
        "Sets queued for duplicate check (could take several minutes)",
      ]);

      loading.value = false;
    };

    return {
      base,
      collection,
      team,
      setsData,
      refresh,
      deleteAllBaseSets,
      keepAllBaseSets,
      imageUrl,
      selectedSet,
      editSet,
      addSet,
      toggleBaseSetSave,
      fileMenu,
      addAFile,
      tempFile,
      allFiles,
      baseFiles,
      removeFileFromSet,
      setAttributes,
      tempAttribute,
      toggleAttribute,
      attributesForSearch,
      attributeMenu,
      selectedAttributes,
      deleteBaseSet,
      addAFileAutocomplete,
      createBaseSet,
      goToPreviousSet,
      goToNextSet,
      previousSetIndex,
      nextSetIndex,
      downloadSets,
      generateOutput,
      loading,
      deleteDuplicateBaseSets,
      generateAllSetsOutput,
      teamRoleIsAdmin,
      allFolders,
      checkFolder,
    };
  },
};
</script>
