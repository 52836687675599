<template>
  <div class="">
    <div class="text-lg flex items-center py-2 justify-between">
      <div class="left">
        <div class="flex items-center">
          <div class="mr-4 text-sm font-semibold">
            {{ set?.name }}
          </div>
          <div class="flex items-center flex-wrap">
            <Tag :name="`Type: ${set.type}`" color="yellow" class="mr-1 mb-1" v-if="set.type" />
          </div>
        </div>
        <div>
          <span class="text-xs">{{ metaTextList }}</span>
        </div>
      </div>
      <div class="flex-grow border-t mx-4"></div>
      <div class="right flex-shrink-0 text-right">
        <button class="btn btn-sm btn-outline ml-2 mb-2" @click.stop="editSet" type="button">
          Edit / Preview
        </button>
        <button
          class="btn btn-sm ml-2 mb-2"
          @click.stop="generateOutput"
          type="button"
          :class="{ 'btn-outline': set.image_base_output_id }"
        >
          {{ set.image_base_output_id ? "Regenerate" : "Generate" }}
        </button>
        <br class="block md:hidden" />
        <button
          class="btn btn-sm btn-success ml-2 mb-2"
          @click.stop="saveSet"
          type="button"
          :class="{ 'btn-outline': set.saved_at }"
        >
          {{ set.saved_at ? "Don't Keep" : "Keep" }}
        </button>
        <button class="btn btn-sm btn-danger ml-2 mb-2" @click.stop="deleteSet" type="button">
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import Tag from "@/components/Tag.vue";
import store from "@/store";

export default {
  name: "SetItem",
  components: {
    Tag,
  },
  props: {
    set: Object,
  },
  emits: ["editSet", "deleteSet", "saveSet", "generateOutput"],
  setup(props, { emit }) {
    const editSet = (event) => {
      emit("editSet", props.set);
    };
    const deleteSet = () => {
      emit("deleteSet", props.set);
    };
    const saveSet = () => {
      emit("saveSet", props.set);
    };
    const generateOutput = () => {
      emit("generateOutput", props.set);
    };

    const metaList = computed(() => {
      if (!props.set?.attributesn) {
        return [];
      }
      let keys = Object.keys(props.set.attributesn);

      return keys.map((k) => {
        return {
          value: props.set.attributesn[k],
          key: k,
        };
      });
    });

    const metaTextList = computed(() => {
      return metaList.value?.map((m) => `${m.key}: ${m.value}`).join(", ");
    });

    return { editSet, deleteSet, metaList, metaTextList, saveSet, generateOutput };
  },
};
</script>

<style lang="postcss"></style>
