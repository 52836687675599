<template>
  <div class="">
    <div class="text-lg flex items-center py-2 justify-between">
      <div class="left flex items-center">
        <div class="ml-2 mr-2 flex items-center justify-center">
          <img :src="imageUrl" style="width: 30px" />
        </div>
        <div>
          <div class="flex items-center">
            <div class="mr-2">{{ file.name }}</div>
          </div>
          <div class="text-xs text-gray-400">
            <span v-if="attributesn?.length">
              Attributes: {{ attributesn.map((a) => `${a.key}: ${a.value}`).join(", ") }}
              <br />
            </span>
          </div>
        </div>
      </div>
      <div class="flex-grow border-t mx-4"></div>
      <div class="right flex items-center">
        <button
          class="btn btn-sm mr-2 border-black text-white"
          @click.stop="toggleFileFlag(file)"
          type="button"
          :class="{
            'btn-warning-outline': !isFlagged,
            'btn-warning': isFlagged,
          }"
        >
          <img :src="require('@/assets/warning.svg')" style="height: 20px" v-if="isFlagged" />
          <img :src="require('@/assets/warning-outline.svg')" style="height: 20px" v-else />
        </button>
        <button class="btn btn-sm btn-outline mr-2" @click.stop="edit" type="button">Edit</button>
        <button class="btn btn-sm btn-danger" @click.stop="deleteFile" type="button">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import Tag from "@/components/Tag.vue";
import store from "@/store";

export default {
  name: "ImageFileItem",
  components: {
    Tag,
  },
  props: {
    file: Object,
  },
  emits: ["editFile", "deleteFile"],
  setup(props, { emit }) {
    const edit = (event) => {
      emit("editFile", props.file);
    };
    const deleteFile = () => {
      emit("deleteFile", props.file);
    };
    const imageUrl = computed(() => {
      return store.getters["team/collectionUrl"](props.file.id);
    });

    const isFlagged = ref(props.file.flagged_at);
    const toggleFileFlag = async (file) => {
      let newFlag = await store.dispatch("team/toggleFileFlag", {
        base_id: props.base_id,
        id: file.id,
      });

      isFlagged.value = newFlag.flagged_at;
    };

    const attributesn = computed(() => {
      if (!props.file.attributesn || Array.isArray(props.file.attributesn)) {
        return null;
      }

      let keys = Object.keys(props.file.attributesn);
      return keys.map((k) => ({
        key: k,
        value: props.file.attributesn[k],
      }));
    });

    return { edit, imageUrl, deleteFile, attributesn, toggleFileFlag, isFlagged };
  },
};
</script>

<style lang="postcss"></style>
