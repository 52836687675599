import { uniqueId } from "lodash-es";

export default {
  computed: {
    idCalc() {
      return this.id || uniqueId("input-");
    },
    errorsCalc() {
      if (this.errorsDisplay === "always" || (this.errorsDisplay === "changed" && this.dirty)) {
        return this.errors;
      }

      return false;
    },
  },
  props: {
    description: String,
    errors: [Array, Boolean],
    errorsDisplay: {
      type: String,
      default: "changed", // or "always"
      validator: (val) => {
        return ["changed", "always", "never"].indexOf(val) !== -1;
      },
    },
    id: String,
    label: String,
    name: String,
    placeholder: String,
    successes: [Array, Boolean],
    testId: String,
    dirty: Boolean,
    disabled: Boolean,
  },
};
