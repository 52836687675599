<template>
  <div class="">
    <div class="text-lg flex items-center py-2 justify-between">
      <div class="left flex items-center">
        <div class="ml-0 mr-2 flex items-center justify-center border">
          <!-- <div class="bg-black w-2 h-2 rounded-full"></div> -->
          <img :src="imageUrl" style="width: 30px" />
        </div>
        <div class="flex items-center">
          <div class="mr-2">
            <div>
              [{{ file.order ?? 0 }}] {{ file.name }}
              <span class="text-xs text-gray-400">(ID: {{ file.id }})</span>
            </div>
            <div class="text-xs text-gray-400">
              <span v-if="attributesn?.length">
                Attributes: {{ attributesn.map((a) => `${a.key}: ${a.value}`).join(", ") }}
                <br />
              </span>
              <span v-if="restrictions?.length">
                Restrictions: {{ restrictions.map((a) => `${a.key}: ${a.value}`).join(", ") }}
                <br />
              </span>
              <span v-if="modifiers?.length">
                Modifiers: {{ modifiers.map((a) => `${a.key}: ${a.value}`).join(", ") }}
              </span>
            </div>
          </div>
          <Tag :name="`L: ${file.layer}`" color="gray" class="mr-1" v-if="file.layer" />
          <Tag :name="`P: ${file.probability}`" color="red" class="mr-1" v-if="file.probability" />
          <Tag
            :name="`W: ${calculatedWight}`"
            color="pink"
            class="mr-1"
            v-if="folderChoice !== 'and'"
          />
          <Tag :name="`T: ${file.type}`" color="yellow" class="mr-1" v-if="file.type" />
        </div>
      </div>
      <div class="flex-grow border-t mx-4"></div>
      <div class="right">
        <button class="btn btn-sm btn-success mr-2 text-white" @click.stop="add" type="button">
          <img :src="require('@/assets/plus-white.svg')" style="height: 20px" />
        </button>
        <button
          class="btn btn-sm mr-2 border-black text-white"
          @click.stop="toggleFileFlag(file.file)"
          type="button"
          :class="{
            'btn-warning-outline': !isFlagged,
            'btn-warning': isFlagged,
          }"
        >
          <img :src="require('@/assets/warning.svg')" style="height: 20px" v-if="isFlagged" />
          <img :src="require('@/assets/warning-outline.svg')" style="height: 20px" v-else />
        </button>
        <button class="btn btn-sm mr-2" @click.stop="edit" type="button">
          <img :src="require('@/assets/edit-white.svg')" style="height: 20px" />
        </button>
        <button class="btn btn-sm btn-danger" @click.stop="deleteFile" type="button">
          <img :src="require('@/assets/trash-white.svg')" style="height: 20px" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import Tag from "@/components/Tag.vue";
import store from "@/store";

export default {
  name: "FileItem",
  components: {
    Tag,
  },
  props: {
    file: Object,
    folderChoice: String,
    baseId: [String, Number],
  },
  emits: ["edit", "deleteFile", "add", "flagFile"],
  setup(props, { emit }) {
    const edit = (event) => {
      emit("edit", props.file);
    };
    const add = (event) => {
      emit("add", props.file);
    };
    const deleteFile = () => {
      emit("deleteFile", props.file);
    };
    const flagFile = () => {
      emit("flagFile", props.file.file);
    };
    const imageUrl = computed(() => {
      return store.getters["team/collectionUrl"](props.file.file.id);
    });
    const calculatedWight = computed(() => {
      if (props.file.weight === 0) {
        return 0;
      }

      if (!props.file.weight) {
        return 1;
      }

      return props.file.weight;
    });

    const attributesn = computed(() => {
      if (!props.file.attributesn || Array.isArray(props.file.attributesn)) {
        return null;
      }

      let keys = Object.keys(props.file.attributesn);
      return keys.map((k) => ({
        key: k,
        value: props.file.attributesn[k],
      }));
    });

    const restrictions = computed(() => {
      if (!props.file.restrictions || Array.isArray(props.file.restrictions)) {
        return null;
      }

      let keys = Object.keys(props.file.restrictions);
      return keys.map((k) => ({
        key: k,
        value: props.file.restrictions[k],
      }));
    });

    const modifiers = computed(() => {
      if (!props.file.modifiers || Array.isArray(props.file.modifiers)) {
        return null;
      }

      let keys = Object.keys(props.file.modifiers);
      return keys.map((k) => ({
        key: k,
        value: props.file.modifiers[k],
      }));
    });

    const isFlagged = ref(props.file.file.flagged_at);
    const toggleFileFlag = async (file) => {
      let newFlag = await store.dispatch("team/toggleFileFlag", {
        base_id: props.base_id,
        id: file.id,
      });

      isFlagged.value = newFlag.flagged_at;
    };

    return {
      edit,
      imageUrl,
      deleteFile,
      calculatedWight,
      attributesn,
      restrictions,
      modifiers,
      add,
      flagFile,
      toggleFileFlag,
      isFlagged,
    };
  },
};
</script>

<style lang="postcss"></style>
