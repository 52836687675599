<template>
  <div class="flex items-stretch">
    <div class="home pt-4 container">
      <div class="flex justify-between items-center mb-6">
        <div class="left flex items-center">
          <button @click="newFolder" class="btn btn-sm btn-success mr-2">Add Folder</button>
          <button @click="newFile" class="btn btn-sm btn-success mr-2">Add File</button>
        </div>
        <div class="right">
          <button @click="openCreateSet" class="btn btn-sm mr-2">Create Sets</button>
          <router-link
            :to="{
              name: 'Team-Collection-Base-Sets',
              params: {
                team_slug: team.slug,
                collection_slug: collection.slug,
                base_id: base_id,
              },
            }"
            class="btn btn-sm btn-outline"
          >
            Go To Sets
          </router-link>
        </div>
      </div>
      <div v-if="baseFiles">
        <div class="">
          <folder-item
            :folder="fold"
            v-for="(fold, f) in baseFiles.folders"
            :key="f"
            @editFile="editFile"
            @editFolder="editFolder"
            folderChoice="and"
            :baseId="base_id"
            @deleteFile="deleteFile"
            @addFile="addFileToPreview"
            @deleteFolder="deleteFolder"
            @flagFile="toggleFileFlag"
          />
        </div>
        <div class="">
          <file-item
            :file="file"
            v-for="(file, f) in baseFiles.files"
            :key="f"
            @edit="editFile"
            @add="addFileToPreview"
            folderChoice="and"
            :baseId="base_id"
            @deleteFile="deleteFile"
            @flagFile="toggleFileFlag"
          />
        </div>
      </div>
    </div>
    <div class="w-1/3 p-4 flex-shrink-0 bg-white" v-if="filesForPreview.length"></div>
    <div
      class="
        fixed
        z-50
        w-1/3
        p-4
        bg-white
        shadow
        h-full
        top-0
        bottom-0
        right-0
        overflow-auto
        flex flex-col
      "
      v-if="filesForPreview.length"
    >
      <div class="flex-shrink-0">
        <div class="font-bold mb-4 text-center">Character Builder Preview</div>
        <div class="relative border mx-auto w-full mb-6" style="max-width: 400px">
          <img :src="require('@/assets/square.png')" class="w-full relative" />
          <img
            :src="imageUrl(file?.file?.id)"
            v-for="(file, f) in filesForPreview"
            :key="f"
            class="absolute top-0"
            :style="{ 'z-index': file?.layer ?? file?.parsed?.layer }"
          />
        </div>
      </div>
      <div class="flex-grow overflow-auto">
        <div
          class="border p-2 flex items-end justify-between"
          v-for="(file, f) in filesForPreview"
          :key="f"
        >
          <div>
            <div class="text-xs">
              {{
                !!file.image_base_folder_id ? `${checkFolder(file.image_base_folder_id)} - ` : null
              }}{{ file.name }}
            </div>
            <input
              class="border p-1 text-xs"
              v-model="file.layer"
              :placeholder="file?.parsed?.layer"
            />
          </div>
          <button @click="removeFileFromPreview(f)" class="btn-danger btn-sm">
            <img :src="require('@/assets/close-white.svg')" style="height: 10px" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <modal v-if="modalFile" :cancel="() => (modalFile = null)">
    <file-form
      :file="modalFile"
      :folderOptions="folderMenu"
      :imageFileOptions="imageFileMenu"
      :baseId="base_id"
      @saved="modalFileCloseRefresh"
    />
  </modal>
  <modal v-if="modalDeleteFile" :cancel="() => (modalDeleteFile = null)">
    <div class="flex flex-col items-center justify-center text-center">
      <div class="text-red font-bold mb-4">Are you sure you want to delete this file?</div>
      <div class="mb-4">{{ modalDeleteFile.name }}</div>
      <div class="mb-6 italic">This cannot be undone</div>
      <button @click="confirmDeleteFile" class="btn btn-danger">Confirm Delete</button>
    </div>
  </modal>
  <modal v-if="modalFolder" :cancel="() => (modalFolder = null)">
    <folder-form
      :folder="modalFolder"
      :folderOptions="folderMenu"
      :baseId="base_id"
      @saved="modalFolderCloseRefresh"
    />
  </modal>
  <modal v-if="modalDeleteFolder" :cancel="() => (modalDeleteFolder = null)">
    <div class="flex flex-col items-center justify-center text-center">
      <div class="text-red font-bold mb-4">Are you sure you want to delete this folder?</div>
      <div class="mb-4">{{ modalDeleteFolder.name }}</div>
      <div class="mb-6 italic">
        This will delete all child folders and file. This cannot be undone - if you want to preserve
        child files and folders, move them first.
      </div>
      <button @click="confirmDeleteFolder" class="btn btn-danger">Confirm Delete</button>
    </div>
  </modal>
  <modal v-if="createSetModal" :cancel="closeCreateSet">
    <div>
      <div class="text-lg font-bold mb-8">Generate Sets</div>
      <form-input v-model="createSetForm.number" label="Number of Sets" class="mb-6" />
      <form-input v-model="createSetForm.type" label="(Optional) Restrict to Type" class="mb-6" />
      <button @click="createSet" class="btn">Generate</button>
    </div>
  </modal>
</template>

<script>
import store from "@/store";
import { onMounted, ref, computed, onUnmounted, reactive } from "vue";
import FolderItem from "@/components/FolderItem.vue";
import FileItem from "@/components/FileItem.vue";
import FormArrayValues from "@/components/FormArrayValues.vue";
import router from "@/router";
import Folder from "@/classes/Folder";
import File from "@/classes/File";
import Modal from "@/components/Modal.vue";
import FileForm from "@/components/FileForm.vue";
import FolderForm from "@/components/FolderForm.vue";
import FormInput from "@/components/FormInput.vue";

const buildMenu = (folder, prefix = "") => {
  let val = {
    value: folder.id,
    label: `${prefix}${folder.name}`,
  };

  let sub = folder.folders?.map((f) => buildMenu(f, `${prefix}${folder.name} / `));

  return [].concat.apply([val], sub);
};

export default {
  name: "Team-Collection-Base-Files",
  components: {
    FolderItem,
    FileItem,
    FormArrayValues,
    Modal,
    FileForm,
    FolderForm,
    FormInput,
  },
  props: {
    base_id: String,
  },
  setup(props) {
    const base = computed(() => {
      return store.getters["team/getBase"](props.base_id);
    });

    const collection = computed(() => {
      return store.state.team.collection;
    });

    const team = computed(() => {
      return store.state.team.item;
    });

    const baseFiles = ref(null);
    const imageFiles = ref(null);
    const folderSelection = ref(null);
    const modalFile = ref(null);
    const modalFolder = ref(null);

    const newFile = () => {
      modalFile.value = new File();
    };

    const newFolder = () => {
      modalFolder.value = new Folder();
    };

    const modalFileCloseRefresh = () => {
      modalFile.value = null;
      refresh();
    };

    const modalFolderCloseRefresh = () => {
      modalFolder.value = null;
      refresh();
    };

    const folderMenu = computed(() => {
      return [].concat.apply(
        [],
        baseFiles.value?.folders.map((f) => buildMenu(f))
      );
    });

    const imageFileMenu = computed(() => {
      return imageFiles.value?.map((i) => ({ value: i.id, label: `${i.name} (ID: ${i.id})` }));
    });

    const refresh = async () => {
      store.dispatch("loading/setGlobal", true);

      try {
        let data = await store.dispatch("team/getBaseFiles", { base_id: props.base_id });

        baseFiles.value = {
          folders: data.folders?.map((fol) => new Folder(fol)),
          files: data.files?.map((fil) => new File(fil)),
        };

        let imageFileData = await store.dispatch("team/getAllImageFiles");
        imageFiles.value = imageFileData;
      } catch (e) {
        store.dispatch("toasts/error", ["Error fetching data", e]);
      }

      store.dispatch("loading/setGlobal", false);
    };

    const editFile = (file) => {
      modalFile.value = { ...file };
    };

    const deleteFile = (file) => {
      modalDeleteFile.value = { ...file };
    };

    const editFolder = (folder) => {
      modalFolder.value = { ...folder };
    };

    const deleteFolder = (file) => {
      modalDeleteFolder.value = { ...file };
    };

    onMounted(() => {
      refresh();
    });

    onUnmounted(() => {});

    const testProp = ref([]);

    const modalDeleteFile = ref(null);
    const confirmDeleteFile = async () => {
      try {
        let fileDeletion = await store.dispatch("team/deleteBaseFile", {
          base_id: props.base_id,
          id: modalDeleteFile.value.id,
        });
      } catch (e) {
        console.log("error deleting: ", e);
        store.dispatch("toasts/error", ["Could not delete file", e]);
      }

      modalDeleteFile.value = null;

      refresh();
    };

    const modalDeleteFolder = ref(null);
    const confirmDeleteFolder = async () => {
      try {
        let fileDeletion = await store.dispatch("team/deleteBaseFolder", {
          base_id: props.base_id,
          id: modalDeleteFolder.value.id,
        });
      } catch (e) {
        console.log("error deleting: ", e);
        store.dispatch("toasts/error", ["Could not delete folder", e]);
      }

      modalDeleteFolder.value = null;

      refresh();
    };

    const createSetForm = reactive({
      number: 20,
      type: null,
    });
    const createSet = async () => {
      store.dispatch("loading/setGlobal", true);

      try {
        let sets = await store.dispatch("team/generateBaseSets", {
          base_id: props.base_id,
          number: createSetForm.number,
          type: createSetForm.type,
        });
        console.log({ sets });
      } catch (e) {
        console.log("error deleting: ", e);
        store.dispatch("toasts/error", ["Could not create sets", e]);
        store.dispatch("loading/setGlobal", false);
      }

      store.dispatch("toasts/success", ["Sets queued for creation!"]);
      createSetForm.number = 30;
      createSetForm.type = null;
      createSetModal.value = null;
      store.dispatch("loading/setGlobal", false);
    };

    const createSetModal = ref(false);
    const openCreateSet = () => {
      createSetModal.value = true;
    };
    const closeCreateSet = () => {
      createSetModal.value = false;
    };

    const filesForPreview = ref([]);
    const addFileToPreview = (file) => {
      if (!filesForPreview.value?.find((f) => f.id === file.id)) {
        filesForPreview.value = [...filesForPreview.value, { ...file }];
      }
    };
    const removeFileFromPreview = (index) => {
      let copyOfArray = [...filesForPreview.value];
      copyOfArray.splice(index, 1);
      filesForPreview.value = [...copyOfArray];
    };
    const imageUrl = (id) => {
      return store.getters["team/collectionUrl"](id);
    };

    const buildFolder = (folder) => {
      let val = folder?.folders?.map((folder) => {
        return {
          value: folder.id,
          label: folder.name,
        };
      });
      let sub = folder.folders?.map((f) => buildFolder(f));
      return [].concat.apply(val, sub);
    };
    const allFolders = computed(() => {
      if (!baseFiles.value) {
        return;
      }
      // let tempBaseFiles = [...baseFiles.value];
      return [].concat.apply(
        baseFiles.value?.folders?.map((folder) => {
          return {
            value: folder.id,
            label: folder.name,
          };
        }),
        baseFiles.value?.folders?.map((f) => buildFolder(f))
      );
    });
    const checkFolder = (folderId) => {
      if (allFolders.value?.find((f) => f.value == folderId)) {
        return allFolders.value.find((f) => f.value == folderId)?.label;
      }

      return null;
    };

    const toggleFileFlag = async (file) => {
      let newFlag = await store.dispatch("team/toggleFileFlag", {
        base_id: props.base_id,
        id: file.id,
      });

      file = newFlag;
    };

    return {
      base,
      team,
      collection,
      baseFiles,
      testProp,
      folderMenu,
      modalFile,
      newFile,
      imageFileMenu,
      editFile,
      modalFileCloseRefresh,
      modalFolder,
      newFolder,
      editFolder,
      modalFolderCloseRefresh,
      modalDeleteFile,
      confirmDeleteFile,
      deleteFile,
      modalDeleteFolder,
      confirmDeleteFolder,
      deleteFolder,
      createSetModal,
      openCreateSet,
      closeCreateSet,
      createSetForm,
      createSet,
      filesForPreview,
      addFileToPreview,
      removeFileFromPreview,
      imageUrl,
      checkFolder,
      allFolders,
      toggleFileFlag,
    };
  },
};
</script>
