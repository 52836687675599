import BaseChild from "./BaseChild";

class File extends BaseChild {
  constructor(data = {}) {
    super(data);
    this.image_file_id = data?.image_file_id ?? null;
    this.file = data?.file ?? {};
  }
}

export default File;
