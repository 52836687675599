<template>
  <div>
    <FormGroup :label="label" :inputId="idCalc" :errors="errorsCalc" :successes="successes">
      <div v-if="prepend" class="input-group-prepend">
        <span class="input-group-text">{{ prepend }}</span>
      </div>
      <slot name="prepend-input"></slot>
      <input
        class="form-control"
        :class="{ 'is-invalid': errorsCalc, 'is-valid': successes, 'font-bold': bold }"
        :data-test="testId"
        :id="idCalc"
        :name="name"
        :placeholder="placeholder"
        :type="type"
        :disabled="disabled"
        v-model="textField"
        :autocomplete="autocomplete"
      />
      <div v-if="append" class="input-group-append">
        <span class="input-group-text">{{ append }}</span>
      </div>
      <slot name="append-input"></slot>
    </FormGroup>
    <div class="options-list flex flex-col">
      <button
        class="rounded bg-white border border-blue py-1 px-2 text-sm mt-2"
        v-for="(option, o) in optionsList"
        :key="o"
        @click="emitData(option.value)"
        type="button"
      >
        {{ option.label }} >
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref, reactive, watch } from "vue";
import FormGroup from "@/components/FormGroup";
import FormMixin from "@/components/FormMixin";

export default {
  components: {
    FormGroup,
  },
  mixins: [FormMixin],
  emits: ["selected"],
  props: {
    append: {
      type: String,
    },
    prepend: {
      type: String,
    },

    type: {
      type: String,
      default: "text",
    },
    bold: {
      type: Boolean,
      default: false,
    },
    label: String,
    modelValue: [String, Number],
    autocomplete: {
      type: String,
      default: "on",
      validator: (val) => {
        return ["on", "off"].includes(val);
      },
    },
    options: {
      type: Array,
      validator: (vals) => {
        if (!Array.isArray(vals)) {
          return false;
        }

        return vals;
      },
    },
  },
  setup(props, { emit }) {
    const allOptions = computed(() => {
      if (props?.options?.[0]?.label) {
        return props.options;
      }

      return props.options?.map((o) => {
        return {
          value: o,
          label: o,
        };
      });
    });
    const emitData = (value) => {
      selected(value);
      textField.value = null;
    };
    const selected = (val) => {
      emit("selected", val);
    };
    const textField = ref(null);
    const optionsList = computed(() => {
      if (!textField.value) {
        return null;
      }

      let searchParts = textField.value
        .split(" ")
        .map((part) => part.replace(/\$/g, "$$$$"))
        .map((part) => {
          return new RegExp(part, "i");
        });

      console.log({ searchParts });

      return allOptions.value.filter((option) => {
        if (textField.value == option.value) {
          return true;
        }
        let foundCount = 0;
        searchParts.forEach((search) => {
          if (search.test(option.label)) {
            foundCount++;
          }
        });
        return foundCount >= searchParts.length;
      });
    });
    return { allOptions, textField, selected, optionsList, emitData };
  },
};
</script>

<style lang="postcss"></style>
