<template>
  <div class="fixed h-full w-full top-0 left-0 z-50 lg:flex items-center justify-center">
    <div class="backdrop absolute w-full h-full bg-black opacity-30" @click="cancelModal"></div>
    <div class="absolute w-full h-full overflow-auto lg:py-12">
      <div
        class="
          modal-content
          mx-auto
          relative
          p-4
          h-full
          lg:max-w-1/2 lg:h-auto
          bg-white
          lg:rounded
          overflow-y-auto
        "
      >
        <div class="inner">
          <div class="flex justify-end top mb-2 p-0">
            <div class="toplink cursor-pointer">
              <div
                @click="cancelModal"
                class="w-8 h-8 border rounded-full flex items-center justify-center"
              >
                x
              </div>
            </div>
          </div>
          <div class="body px-6 pb-6">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, onUnmounted } from "vue";
export default {
  name: "Modal",
  props: {
    cancel: Function,
  },
  setup(props) {
    onMounted(() => {
      document.body.classList.add("overflow-hidden");
    });

    onUnmounted(() => {
      document.body.classList.remove("overflow-hidden");
    });

    const cancelModal = () => {
      props.cancel();
    };

    return {
      cancelModal,
    };
  },
};
</script>

<style lang="postcss"></style>
