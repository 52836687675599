<template>
  <div class="">
    <div class="text-lg flex items-center py-2 justify-between">
      <div class="left">
        <div class="flex items-center">
          <div class="mr-4 text-sm font-semibold">
            {{ output?.display_name }}{{ output?.sets?.length ? "*" : null }}
          </div>
        </div>
        <div>
          <!-- <span class="text-xs">
            {{ metaOrigTextList }}
          </span>
          <br /> -->
          <span class="text-xs">
            {{ metaTextList }}
          </span>
        </div>
      </div>
      <div class="flex-grow border-t mx-4"></div>
      <div class="right flex-shrink-0 text-right">
        <button class="btn btn-sm btn-outline ml-2 mb-2" @click.stop="editOutput" type="button">
          Edit / Preview
        </button>
        <br class="block md:hidden" />
        <!-- <button class="btn btn-sm btn-success ml-2 mb-2" @click.stop="saveOutput" type="button">
          {{ output.saved_at ? "Unsave" : "Save" }}
        </button> -->
        <button class="btn btn-sm btn-danger ml-2 mb-2" @click.stop="deleteOutput" type="button">
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import Tag from "@/components/Tag.vue";
import store from "@/store";

export default {
  name: "OutputItem",
  components: {
    Tag,
  },
  props: {
    output: Object,
  },
  emits: ["editOutput", "deleteOutput", "saveOutput"],
  setup(props, { emit }) {
    const editOutput = (event) => {
      emit("editOutput", props.output);
    };
    const deleteOutput = () => {
      emit("deleteOutput", props.output);
    };
    const saveOutput = () => {
      emit("saveOutput", props.output);
    };

    const metaList = computed(() => {
      if (!props.output?.metadata) {
        return [];
      }
      let keys = Object.keys(props.output.metadata);

      return keys.map((k) => {
        return {
          value: props.output.metadata[k],
          key: k,
        };
      });
    });

    const metaOrigList = computed(() => {
      if (!props.output?.metadata_original) {
        return [];
      }
      let keys = Object.keys(props.output.metadata_original);

      return keys.map((k) => {
        return {
          value: props.output.metadata_original[k],
          key: k,
        };
      });
    });

    const metaTextList = computed(() => {
      return metaList.value?.map((m) => `${m.key}: ${m.value}`).join(", ");
    });

    const metaOrigTextList = computed(() => {
      return metaOrigList.value?.map((m) => `${m.key}: ${m.value}`).join(", ");
    });

    return {
      editOutput,
      deleteOutput,
      metaList,
      metaTextList,
      saveOutput,
      metaOrigList,
      metaOrigTextList,
    };
  },
};
</script>

<style lang="postcss"></style>
