<template>
  <div class="">
    <div class="text-lg font-bold mb-8">{{ fileRecord.id ? "Update" : "Create" }} File</div>
    <div class="">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <form-select
          v-model="fileRecord.image_base_folder_id"
          :options="folderOptions"
          :noneLabel="'ROOT'"
          label="Folder"
          class="mb-6"
        />
        <form-input
          v-model="fileRecord.order"
          placeholder="Order"
          label="File Order"
          type="number"
          class="mb-6"
        />
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <form-input v-model="fileRecord.name" placeholder="Name" label="Name" class="mb-6" />
        <form-input v-model="fileRecord.type" placeholder="Type" label="Type" class="mb-6" />
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <form-input
          v-model="fileRecord.layer"
          placeholder="Layer"
          label="Layer Number"
          type="number"
          class="mb-6"
        />
        <form-input
          v-model="fileRecord.probability"
          placeholder="Probability"
          label="Probability"
          class="mb-6"
        />
        <form-input v-model="fileRecord.weight" placeholder="Weight" label="Weight" class="mb-6" />
      </div>
      <hr class="mb-4" />
      <form-array-values v-model="fileRecord.attributesn" label="Attributes" class="mb-6" />
      <hr class="mb-4" />
      <form-array-values v-model="fileRecord.restrictions" label="Restrictions" class="mb-6" />
      <hr class="mb-4" />
      <form-array-values v-model="fileRecord.modifiers" label="Modifiers" class="mb-6" />
      <hr class="mb-4" />
      <div class="mb-6">
        <form-select
          v-model="fileRecord.image_file_id"
          :options="imageFileOptions"
          :noneLabel="'Upload New Image'"
          label="Image File"
          class="mb-6"
        />
      </div>

      <div class="mb-6" v-if="!fileRecord.image_file_id">
        <label class="block mb-1 text-sm">Upload File"</label>
        <input type="file" name="file" @change="setFile" />
      </div>

      <div class="mb-6">
        <form-autocomplete
          @selected="
            (val) => {
              fileRecord.image_file_id = val;
            }
          "
          :options="imageFileOptions"
          label="Or Search For File"
          class="mb-6"
        />
      </div>
    </div>
    <div>
      <button @click="save" class="btn">Save</button>
    </div>
  </div>
</template>

<script>
import { computed, ref, reactive, watch } from "vue";
import FormInput from "@/components/FormInput";
import File from "@/classes/File";
import FormSelect from "./FormSelect.vue";
import FormArrayValues from "./FormArrayValues.vue";
import store from "@/store";
import FormAutocomplete from "./FormAutocomplete.vue";

export default {
  name: "FileForm",
  components: {
    FormInput,
    FormSelect,
    FormArrayValues,
    FormAutocomplete,
  },
  props: {
    file: [File, Object],
    folderOptions: Array,
    imageFileOptions: Array,
    baseId: [String, Number],
  },
  emits: ["saved"],
  setup(props, { emit }) {
    const fileRecord = reactive(new File(props.file));

    const uploadFile = ref(null);
    const setFile = (event) => {
      uploadFile.value = event.target.files[0];
    };

    const save = async () => {
      try {
        let image_file_id = fileRecord.image_file_id;
        if (!fileRecord.image_file_id) {
          // await upload file
          let req = await store.dispatch("team/uploadFile", {
            base_id: props.baseId,
            file: uploadFile.value,
          });
          image_file_id = req.id;
        }

        let baseFile = await store.dispatch("team/createBaseFile", {
          ...fileRecord,
          base_id: props.baseId,
          image_file_id,
        });

        emit("saved", baseFile);
      } catch (e) {
        console.log("error creating: ", e);
        store.dispatch("toasts/error", ["Could not create/update file", e]);
      }

      // await createFile
    };

    return { save, fileRecord, setFile };
  },
};
</script>

<style lang="postcss"></style>
